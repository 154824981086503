/* eslint-disable max-lines */
export const faqs = {
    olxid: {
        title: 'Pertanyaan umum',
        tabs: [{ title: 'Jual Mobil Bekas', categoryId: 'sell' }, { title: 'Beli Mobil Bekas', categoryId: 'buy' }],
        showTabs: true,
        content: [
            {
                a: 'OLXmobbi menyediakan mobil bekas berkualitas dengan berbagai pilihan yang telah dipilih sesuai kebutuhan dan ekspektasi pelanggan. Seluruh mobil yang kami sediakan memiliki kualitas dan tersertifikasi serta harga yang terbaik demi kenyamananmu.',
                q: 'Mengapa membeli mobil dari OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'OLXmobbi hanya menyediakan mobil bekas berkualitas yang telah lulus inspeksi dan tersertifikasi untuk memastikan mobil yang kamu beli bebas dari tabrakan besar dan banjir.',
                q: 'Bagaimana kualitas mobil yang dijual OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'Semua mobil yang tersedia dimiliki langsung oleh OLXmobbi.',
                q: 'Siapa pemilik mobil-mobil yang dijual OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'Ya, kami menyediakan layanan tukar tambah. Konsultan kami akan membantu kamu selama proses tukar tambah kendaraan lama ke kendaraan baru yang kamu inginkan di OLXmobbi.',
                q: 'Apakah OLXmobbi menyediakan layanan tukar tambah?',
                categoryId: 'buy'
            },
            {
                a: 'Kamu dapat membeli mobil di OLXmobbi secara tunai maupun kredit. Kami memiliki rekanan penyedia layanan kredit mobil sesuai dengan kebutuhanmu yang akan dibantu prosesnya oleh konsultan kami.',
                q: 'Bagaimana cara pembelian mobil di OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'Tidak, semua mobil yang dijual OLXmobbi bebas dari proses cicilan kredit maupun leasing.',
                q: 'Apakah OLXmobbi menjual mobil yang masih dalam proses cicilan kredit maupun leasing?',
                categoryId: 'buy'
            },
            {
                a: 'OLXmobbi menyediakan mobil bekas berkualitas yang telah dipilih sesuai kebutuhan dan ekspektasi pelanggan, dengan:\n1. Lulus inspeksi dan tersertifikasi\n2. Jaminan 7 hari uang kembali\n3. Garansi mesin & transmisi hingga 1 tahun\n4. Layanan home test drive\n5. Gratis biaya jasa perawatan sesuai dengan syarat dan ketentuan berlaku',
                q: 'Apa keuntungan dari membeli mobil di OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'Tidak, kami memberikan keuntungan yang sama baik untuk pembelian tunai maupun kredit.',
                q: 'Apakah keuntungan yang saya dapatkan dari OLXmobbi berbeda jika saya membeli secara kredit?',
                categoryId: 'buy'
            },
            {
                a: 'Harga yang ditawarkan OLXmobbi merupakan harga terbaik dan kompetitif tanpa biaya tambahan di luar harga yang tercantum.',
                q: 'Apakah harga yang ditampilkan masih dapat dinegosiasikan?',
                categoryId: 'buy'
            },
            {
                a: 'Jaminan pengembalian uang ini sesuai dengan kebijakan kami yang berlaku:\n1. Kondisi mobil sesuai dengan hasil inspeksi (bebas tabrak & banjir)\n2. Tidak terdapat modifikasi pada kilometer mobil (tidak diputar) dan hanya berlaku untuk mobil yang dijual dan dimiliki OLXmobbi\n3. Seluruh dokumen mobil lengkap sesuai pada saat serah terima pembelian awal\n4. Tidak ada kerusakan lain/tambahan pada kendaraan, terutama mesin dan komponen mobil',
                q: 'Apa persyaratan garansi 7 hari uang kembali?',
                categoryId: 'buy'
            },
            {
                a: 'Cukup cari dan temukan mobil yang kamu inginkan di [olxmobbi.co.id](http://olxmobbi.co.id), lalu pilih "Book test drive" dan masukkan jadwal test drive sesuai kenyamananmu. Jika jadwal yang tercantum tidak sesuai, kamu dapat menghubungi konsultan kami melalui "Chat dengan penjual".',
                q: 'Bagaimana cara membuat jadwal test drive untuk mobil OLXmobbi yang saya inginkan?',
                categoryId: 'buy'
            },
            {
                a: 'Kamu bisa melakukan test drive di rumah atau store kami dengan rentang waktu pukul 09.00 - 17.00 WIB.',
                q: 'Kapan dan di mana saya bisa melakukan test drive?',
                categoryId: 'buy'
            },
            {
                a: 'Jika kamu telah menemukan mobil yang diinginkan di OLXmobbi, kamu dapat menghubungi konsultan kami untuk melakukan pemesanan mobil. Mobil yang telah dipesan tidak akan dapat dipesan atau menerima permintaan test drive dari pelanggan lain.',
                q: 'Bagaimana saya dapat melakukan pemesanan mobil?',
                categoryId: 'buy'
            },
            {
                a: 'Konsultan kami akan membantumu dalam proses pengajuan kredit melalui lembaga penyedia keuangan yang tersedia dan telah bekerjasama dengan OLXmobbi.',
                q: 'Bagaimana langkah-langkah pembelian mobil secara kredit di OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'Kamu dapat mengambil mobil yang telah kamu pesan dan bayar di store kami atau kami kirimkan ke alamat yang kamu pilih.',
                q: 'Bagaimana OLXmobbi mengirim mobil yang saya pesan?',
                categoryId: 'buy'
            },
            {
                a: 'Dokumen yang kamu butuhkan:\n1. KTP (Kartu Tanda Penduduk)\n2. KTP Pasangan (jika sudah menikah)\n3. Kartu Keluarga\n4. NPWP\n5. Slip gaji / keterangan atas penghasilan\n6. Bukti kepemilikan tempat tinggal\n7. Dokumen lainnya (sesuai dengan persyaratan yang dibutuhkan lembaga pembiayaan terkait)',
                q: 'Apa saja persyaratan dokumen yang dibutuhkan untuk pembelian mobil secara kredit?',
                categoryId: 'buy'
            },
            {
                a: 'Belum tentu, kamu harus melewati proses validasi dan cek kelayakan yang dilakukan lembaga pembiayaan terlebih dahulu.',
                q: 'Apakah pengajuan kredit saya pasti diterima oleh lembaga pembiayaan?',
                categoryId: 'buy'
            },
            {
                a: 'Kamu harus membayar DP (Down Payment) sesuai perhitungan yang diberikan lembaga pembiayaan, setelah itu baru mobil dapat kami serah terimakan kepada pelanggan.',
                q: 'Jika pengajuan kredit saya diterima lembaga pembiayaan, bagaimana proses selanjutnya?',
                categoryId: 'buy'
            },
            {
                a: 'Bisa, namun akan membutuhkan waktu yang lebih lama. Kami merekomendasikan untuk mengajukan kredit kepada lembaga pembiayaan yang sudah bekerjasama dengan OLXmobbi.',
                q: 'Bagaimana jika saya ingin mengajukan kredit tapi menggunakan lembaga pembiayaan yang tidak bekerjasama dengan OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'Bisa, kami memiliki beberapa rekanan perusahaan asuransi yang sudah bekerjasama. Konsultan kami akan membantu kamu untuk mengetahui lebih lanjut mengenai hal ini.',
                q: 'Apakah saya dapat melakukan pembelian asuransi mobil di OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: 'OLXmobbi adalah bisnis layanan online dan offline jual beli mobil bekas yang berbasis teknologi, sekaligus one stop solution dalam ekosistem pasar mobil bekas, yang memiliki 30 Store & Inspection Center yang tersebar di 10 kota besar di Indonesia seperti Jabodetabek, Bandung, Semarang, Surabaya, Malang, Denpasar, Pekanbaru, Palembang, Balikpapan, dan Medan. \n\nOLXmobbi menyediakan solusi terlengkap untuk pembeli dan penjual mobil bekas, mulai dari inspeksi mobil, jual mobil, beli mobil, tukar tambah, dan menjanjikan pelayanan yang aman, nyaman, mudah, dan cepat.\n\nInformasi lengkap mengenai [OLXmobbi](https://help.olxmobbi.co.id/hc/id/articles/29920094682265-Apa-itu-OLXmobbi)',
                q: 'Siapa itu OLXmobbi?',
                categoryId: 'sell'
            },
            {
                a: 'Kamu bisa tahu perkiraan harga jual mobil dengan mengisi data spesifikasi mobil melalui website olxmobbi.co.id. Jika kamu tertarik dengan perkiraan harga tersebut, buatlah jadwal inspeksi mobil di lokasi inspeksi terdekat. Saat ini kami memiliki 10 lokasi inspeksi di Jabodetabek, Bandung, Semarang, Surabaya, Malang, Denpasar, Pekanbaru, Palembang, Balikpapan, dan Medan. Silakan memilih tempat, tanggal, dan waktu yang sesuai dengan keinginan kamu.',
                q: 'Cek harga jual mobil secara gratis',
                categoryId: 'sell'
            },
            {
                a: 'Datanglah ke lokasi inspeksi OLXmobbi sesuai dengan jadwal yang kamu terima. Tim profesional siap membantu kamu untuk melakukan inspeksi mobil secara menyeluruh dalam waktu sekitar 60 menit. Pastikan kamu membawa kelengkapan dokumen untuk mempercepat proses penjualan mobil.\n\n1. Dokumen dan barang yang dibutuhkan:\n - **Mobil yang ingin dijual**\n - **BPKB dan SNTK asli**\n - **Faktur kendaraan asli**\n - **Buku servis dan buku manual**\n - **SPH** (Apabila atas nama perusahaan)\n - **Kelengkapan kendaraan kamu** (kunci ban, ban cadangan, dongkrak, dll)\n - **Blanko kosong**\n - **KTP asli dan fotokopi**\n - **Buku KEUR** (untuk mobil yang memiliki KEUR)\n - **Foto buku rekening** (untuk pembayaran jika transaksi berhasil)\n - **Surat kuasa** (Apabila mobil yang dijual bukan atas nama kamu)\n2. Tambahan untuk mobil dalam angsuran:\n - **Fotokopi BPKB terlegalisir**\n - **Perjanjian pembiayaan**\n - **Fotokopi histori pembayaran dari perusahaan pembiayaan dan bukti pembayaran terakhir**\n - **Fotokopi faktur kendaraan yang terlegalisir**\n\nJangan lupa untuk menyimpan semua barang pribadi kamu yang ada di dalam mobil sebelum inspeksi dimulai.',
                q: 'Inspeksi mobil gratis',
                categoryId: 'sell'
            },
            {
                a: 'Setelah proses inspeksi selesai, kami akan memberikan harga penawaran akhir. Jika disepakati, selanjutnya akan dilakukan proses pengecekan dokumen dan  proses pembayaran, yang nantinya akan ditransfer langsung ke rekening kamu dalam ±60 menit, setelah deal & dokumen lengkap diterima. Mudah kan?',
                q: 'Jual mobil dan langsung terima pembayaran!',
                categoryId: 'sell'
            },
            {
                a: 'Ya, kamu dapat menjual mobil dalam masa kredit. Setelah kamu setuju untuk menjual kendaraan kamu dengan harga penawaran akhir kami, kamu perlu untuk mengurus pelunasan dipercepat pada perusahaan pembiayaan, seluruh kewajiban harus diselesaikan dan dokumen terkait kepemilikan kendaraan dirilis. Ingatlah bahwa kami tidak membeli mobil dengan kredit, komitmen, atau pembiayaan mandiri yang aktif.',
                q: 'Apakah bisa jual mobil kredit?',
                categoryId: 'sell'
            },
            {
                a: 'Kamu dapat menjual mobil sesuai dengan tahun mobil kamu, dan kamu dapat melihat model dan tahun mobil kamu di olxmobbi.co.id untuk melihat estimasi harga mobil kamu, kunjungi lokasi kami untuk melakukan proses inspeksi, sehingga kamu bisa melakukan proses penjualan. Pembayaran instan dalam ±60 menit, setelah deal & dokumen lengkap diterima, gratis tanpa biaya apapun. Informasi lebih lengkap silahkan hubungi Call Center di 150264.\n\nJika kamu tidak dapat menemukan detail mobilmu dalam penawaran, kamu dapat menghubungi kami di info@olx.co.id atau hubungi kami di 150264 dan kami akan dengan senang hati membantu kamu.',
                q: 'Apakah penjualan mobil di OLXmobbi, tidak terbatas dengan tahun?',
                categoryId: 'sell'
            },
            {
                a: 'Kami akan melakukan inspeksi secara mekanis dan memeriksa langsung ke seluruh kondisi mobil. Mobilmu akan diinspeksi oleh tenaga ahli, sebanyak 188 titik. Langkah ini penting sebelum menjual kendaraanmu. Berikut ini adalah langkah dalam evaluasi yang akan kami lakukan:\n- Dapatkan estimasi penawaran harga pertama secara online, dengan mengunjungi website kami di olxmobbi.co.id.\n- Jika kamu menyetujui estimasi penawaran harga pertama dari kami, silakan lanjutkan langkah berikutnya dengan booking jadwal inspeksi yang kamu inginkan.\n- Setelah berhasil melengkapi data-data mengenai mobil kamu dan data pribadi kamu, kamu akan segera dihubungi melalui telepon atau chat dari konsultan kami, yang akan memandu kamu langkah demi langkah, untuk membantu penjualan mobil dan menginformasikan dokumen atau kelengkapan kendaraan sebelum penjualan mobil.\n- Jika kamu memutuskan untuk menerima, datang ke lokasi cabang terdekat atau inspektor kami datang ke rumahmu untuk memverifikasi dokumentasi, inspeksi mobilmu di 188 titik dan menyelesaikan penjualan. Transaksi akan dilakukan pada hari yang sama dan langsung ke rekeningmu dalam ±60 menit, setelah deal & dokumen lengkap diterima.',
                q: 'Bagaimana cara kerja inspeksi mobil di olxmobbi.co.id?',
                categoryId: 'sell'
            },
            {
                a: 'Penjadwalan disarankan agar proses inspeksi berjalan lancar. Silakan isi detail mobil kamu, buat jadwal inspeksi dan kami akan menghubungi untuk konfirmasi lebih lanjut. Selain itu, kamu bisa menghubungi kami di 150264. Sampai bertemu di lokasi inspeksi!',
                q: 'Apakah saya harus membuat janji?',
                categoryId: 'sell'
            },
            {
                a: 'Lokasi inspeksi OLXmobbi beroperasi dari pukul 09.00-17.00 (terkecuali lokasi inspeksi yang berada di area mall, mengikuti jadwal operasional mall). Jika kamu terkendala untuk datang ke lokasi, kamu bisa memanfaatkan layanan Home Inspection. Silakan kunjungi website [olxmobbi.co.id](http://olxmobbi.co.id) untuk penjadwalan, petugas inspeksi kami akan mendatangi lokasi kamu GRATIS atau tanpa dipungut biaya. Informasi lebih lengkap hubungi Call Center di 150264.',
                q: 'Jam Operasional lokasi Inspeksi',
                categoryId: 'sell'
            },
            {
                a: 'Layanan Home Inspection dapat dilakukan dengan cara book di website kami [olxmobbi.co.id](http://olxmobbi.co.id) atau menghubungi Call Center di 150264. Layanan Home Inspection sudah hadir di Jabodetabek, Bandung, Semarang, Surabaya, Malang, Denpasar, Pekanbaru, Palembang, Balikpapan, dan Medan.\nSilahkan memilih tempat, tanggal, dan waktu yang sesuai dengan keinginan kamu.',
                q: 'Gimana cara melakukan home inspection & ada dimana saja?',
                categoryId: 'sell'
            },
            {
                a: 'Agar proses pembayaran dapat segera dilakukan, mohon membawa kelengkapan dokumen yang dibutuhkan.\n1. Dokumen dan barang yang dibutuhkan:\n- **Mobil yang ingin dijual**\n- **BPKB dan SNTK asli**\n- **Faktur kendaraan asli**\n- **Buku servis dan buku manual**\n- **SPH** (apabila atas nama perusahaan)\n- **Kelengkapan kendaraan kamu** (kunci ban, ban cadangan, dongkrak, dll)\n- **Blanko kosong**\n- **KTP asli dan fotokopi**\n- **Buku KEUR** (untuk mobil yang memiliki KEUR)\n- **Foto buku rekening** (untuk pembayaran jika transaksi berhasil)\n- **Surat kuasa** (Apabila mobil yang dijual bukan atas nama kamu)\n\n2. Tambahan untuk mobil dalam angsuran:\n- **Fotokopi BPKB terlegalisir**\n- **Perjanjian pembiayaan**\n- **Fotokopo histori pembayaran dari perusahaan pembiayaan dan bukti pembayaran terakhir**\n- **Fotokopi faktur kendaraan yang terlegalisir**\n\nJangan lupa untuk menyimpan semua barang pribadi kamu sebelum inspeksi dimulai.',
                q: 'Apa saja dokumen yang harus saya bawa saat inspeksi?',
                categoryId: 'sell'
            },
            {
                a: 'Perkiraan harga jual di awal merupakan kisaran harga jual mobil berdasarkan tipe dan spesifikasi. Tim inspeksi berpengalaman akan memeriksa mobilmu di 188 titik untuk mengetahui kondisi mobil menyeluruh. Berdasarkan kondisi aktual dan nilai pasaran, kami akan memberikan kamu perkiraan harga jual.',
                q: 'Apakah harga akhir bisa berbeda dari perkiraan harga online?',
                categoryId: 'sell'
            },
            {
                a: 'Kamu dapat menghubungi Call Center 150264 atau dapat mengirimkan email info@olx.co.id.',
                q: 'Bagaimana cara menjadi partner dealer di olxmobbi.co.id?',
                categoryId: 'sell'
            },
            {
                a: 'Apabila kamu memiliki pertanyaan dapat menghubungi kami melalui Call Center OLX di 150264, kirimkan pesan melalui WhatsApp di +6281944216470 atau kirimkan email di info@olx.co.id dan kami akan dengan senang hati membantu kamu. Kami juga dapat melayani kamu melalui kotak masuk di media sosial kami dan informasi menarik lainnya seputar OLXmobbi, temukan kami di:\nIG: @olx_id & @olxmobbi\nFB: OLX Indonesia\nYT: OLX Indonesia\nTiktok: @olx_id',
                q: 'BAGAIMANA KAMU BISA MENGHUBUNGI KAMI?',
                categoryId: 'sell'
            },
            {
                a: 'Untuk saat ini, kamu dapat membayar booking fee dengan mudah melalui aplikasi OLX, tetapi kamu juga dapat langsung ke OLXmobbi Store untuk membayar booking fee.\n\nMohon diperhatikan, apabila mobil yang sedang kamu cari memiliki banyak peminat, kami sarankan untuk membayar booking fee melalui aplikasi OLX, agar mobil yang kamu inginkan tidak terjual oleh pelanggan lain.',
                q: 'Apakah booking fee di OLXmobbi hanya bisa dibayar melalui aplikasi?',
                categoryId: 'buy'
            },
            {
                a: 'Saat ini kami menyediakan dua metode untuk memudahkan dalam melakukan pembayaran booking fee, yaitu melalui transfer bank dan credit card.',
                q: 'Apakah booking fee bisa dilakukan dengan menggunakan metode CC?',
                categoryId: 'buy'
            },
            {
                a: 'Booking fee dapat dikembalikan dalam masa waktu 7 hari dari tanggal transaksi pembayaran booking fee.',
                q: 'Apakah ada masa berlakunya untuk pembayaran booking fee mobil di OLXmobbi?',
                categoryId: 'buy'
            },
            {
                a: '- Jika SPK batal sepihak sementara customer sudah cek unit/proses leasing sedang berjalan/sedang mengajukan perbaikan akan dikenakan sanksi berupa potongan  50% dari nominal Booking Fee.\n\n- Booking Fee bisa dipotong seluruhnya apabila ada pembatalan sepihak dari customer setelah customer mengajukan perbaikan tambahan yang menyebabkan biaya tambahan dari OLXmobbi. Adapun contoh yang dimaksud adalah perpanjang pajak/perbaikan tambahan.\n\n- Jika Booking Fee (SPK) batal karena kondisi kesalahan atau kelalaian dari pihak OLXmobbi, unit tidak sesuai, atau karena reject leasing, pengembalian dana akan dilakukan secara full.',
                q: 'Apabila customer melakukan pembatalan sepihak setelah pembayaran booking fee, apakah booking fee tetap bisa dikembalikan?',
                categoryId: 'buy'
            },
            {
                a: 'Silakan menghubungi staf kami di OLXmobbi Store terdekat. Kamu juga dapat menghubungi Call Center OLX Indonesia di Live Chat Whatsapp Official OLX Indonesia di +6281944216470 atau melalui layanan telepon di 150264 atau email kami di info@olx.co.id.',
                q: 'Bagaimana proses (flow) pengembalian booking fee?',
                categoryId: 'buy'
            },
            {
                a: 'Customer bisa melakukan pembayaran booking fee melalui OLXmobbi Store. Namun jika ingin melakukan pembayaran booking fee lewat aplikasi, maka perlu buat akun OLX terlebih dahulu dengan menggunakan nomor telepon, email, ataun akun Google-mu.',
                q: 'Jika customer tidak memiliki akun OLX, apakah bisa melakukan booking fee?',
                categoryId: 'buy'
            }
        ]
    },
    olxmx: {
        title: 'Preguntas frecuentes',
        content: [
            {
                a: 'En OLXmobbi nos dedicamos a la compra y venta de autos usados. Con nosotros puedes vender, comprar y financiar tu auto de manera rápida, transparente y segura. \n\nNuestra plataforma se encuentra en Estados Unidos, México, Colombia, Chile, Argentina, India, Indonesia, Nigeria y Pakistán, con transacciones de más de 130 mil autos por año.',
                q: '¿Quiénes somos?'
            },
            {
                a: 'Revisa en nuestra página cuál es la [sucursal más cercana a tu ubicación](/sucursales/).\n\nSi tienes alguna duda, ponte en contacto con nosotros al teléfono 800 022 2886 o WhatsApp al 5578887881. O bien, escríbenos a contacto-mx@olxautos.com',
                q: '¿Dónde están ubicados?'
            },
            {
                a: '¡Es muy fácil! Los pasos que debes seguir son:\n1) Ingresa los datos de tu auto en nuestro cotizador para obtener una oferta preliminar.\n2) Realiza una Evaluación Online para confirmar una oferta final, o si lo prefieres, agenda una cita presencial.\n3) Agenda la entrega de tu auto y recibe el pago mediante transferencia electrónica de manera segura y sin intermediarios.',
                q: '¿Cómo puedo vender mi auto a OLXmobbi?'
            },
            {
                a: 'Compramos cualquier auto nacional sin importar marca, modelo, versión; siempre y cuando sean autos del 2010 en adelante y con un kilometraje menor a 150,000 km.\n\nSi no encuentras los datos de tu auto en el cotizador, escríbenos a contacto-mx@olxautos.com o llámanos al 800 022 2886. ¡Te atenderemos con gusto!\n\nRecuerda, revisar nuestra [guía de documentos para comprar un auto](https://blog.olxautos.com.mx/10-documentos-que-necesitas-para-vender-tu-auto-usado/).',
                q: '¿Compran cualquier auto?'
            },
            {
                a: 'Tenemos 2 modalidades en las que podemos inspeccionar tu auto:\n1) Recibe una cotización en línea, después nuestros asesores se comunicarán contigo para guiarte en el paso a paso de la evaluación del estado y documentación de tu auto. Si estás de acuerdo con la oferta final que te hagan, agendaremos una cita para acudir por tu nuevo auto y concretar la venta.\n2) Agenda una cita de inspección presencial en una de nuestras sucursales. Los procesos de revisión de la condición del auto y verificación de los documentos se realizan de forma simultánea, al finalizar te hacemos una oferta final por tu auto.',
                q: '¿Cómo funciona el proceso de evaluación del auto?'
            },
            {
                a: 'Consulta nuestra [lista de documentos requeridos](/documentos-requeridos) para la compra de tu auto.',
                q: '¿Qué documentos necesitan para comprar mi auto?'
            },
            {
                a: 'Sí, los adeudos en tenencias o multas, se descuentan de la oferta final. Mientras que los adeudos de verificaciones deberán ser liquidados para poder finalizar la venta.\n\nEn OLXmobbi no compramos autos con crédito, empeño o autofinanciamiento activo. El auto tiene que estar liquidado y liberada la factura para hacer la compra.',
                q: '¿Compran mi auto aunque deba tenencias y verificaciones?'
            },
            {
                a: 'Lamentablemente no, para poder hacerte una oferta, el auto debe estar completamente pagado y liberada la factura.',
                q: '¿Compran mi auto aunque todavía deba el financiamiento, crédito o si se encuentra empeñado?'
            },
            {
                a: 'Compramos autos a partir del año 2010 en adelante sin importar marca o modelo. Por el momento no compramos autos refacturados por aseguradora, refacturados, con reporte de recuperado, inundados o que hayan sido usados como taxi.\n \nSi te interesa vendernos tu auto, revisa nuestra [guía de documentos para comprar un auto](https://blog.olxautos.com.mx/10-documentos-que-necesitas-para-vender-tu-auto-usado/).',
                q: '¿Compran todo tipo de autos?'
            },
            {
                a: 'Así es, solamente descontamos el costo de las reparaciones en la oferta final. \n\nNo recibimos autos en grúa, remolque o que no lleguen a un centro de atención por sus propios medios.',
                q: 'Mi auto tiene defectos mecánicos o estéticos, ¿aún así lo compran?'
            },
            {
                a: 'El precio se determina de acuerdo a la oferta y demanda del mercado, el estado real del vehículo y su documentación, también pueden influir las multas y/o pagos atrasados.\n\nEn OLXmobbi desarrollamos un algoritmo de inteligencia, basado en información real del mercado y las condiciones reales de cada auto para brindarte la mejor oferta posible.',
                q: '¿Cómo determinan el precio del auto?'
            },
            {
                a: 'Tu pago se transferirá directamente a la cuenta bancaria que tú nos indiques. No realizamos pagos en efectivo por razones de seguridad y transparencia para ambos.',
                q: '¿Cómo recibo mi pago?'
            },
            {
                a: 'Para los profesionales dedicados a la compra-venta de autos, tenemos una [plataforma especializada](https://dealerzone.olxautos.com.mx/). ¡Regístrate sin costo y recibe más beneficios para tu negocio!\n\nPuedes ponerte en contacto a través de nuestro [Facebook para Dealers](https://www.facebook.com/groups/DealersZone/), llamarnos al 5579990195 o escribirnos a afiliaciones-mx@olxautos.com',
                q: 'Me dedico a comprar y vender autos usados, ¿puedo comprarles directamente?'
            },
            {
                a: 'Tenemos 4 maneras en las que puedes encontrar tu auto ideal:\n1) Llámanos al 5574554938, cuéntanos qué auto buscas y tu presupuesto aproximado, así te daremos alternativas personalizadas. \n2) Compra directamente en [nuestro sitio web](/comprar-un-auto).\n3) Compra en nuestra [tienda en línea de Facebook](https://www.facebook.com/olxautosmexico/shop/).\n4) O visítanos en nuestros puntos de exhibición:\n- **Estado de México:** Av. Lago de Guadalupe s/n, Col. San Pedro Barrientos, Tlalnepantla Edo. Mex. Centro Comercial Plaza Lago, estacionamiento nivel 2.\n- **Monterrey:** Av. Hacienda de Peñuelas 8681, col. Cumbres las Palmas Residencial, Monterrey, N.L. Centro Comercial Plaza Cumbres, sótano 3.\n- **Guadalajara:** Calzada Jesús González Gallo 2776, col. El Rosario, Guadalajara, Jal.',
                q: 'Quiero comprar un auto con ustedes, ¿dónde puedo verlos?'
            },
            {
                a: 'Nuestros autos pasan por una evaluación minuciosa de 240 puntos, consideramos: la parte estética, mecánica y documental. En la ficha técnica de cada uno encontrarás su estado real.',
                q: '¿Cómo puedo saber si el auto que me interesa comprar está en buen estado?'
            },
            {
                a: 'Sí, nuestros expertos harán una evaluación de tu auto y si cumple con nuestros estándares, te haremos una oferta, misma que se descontará del precio final del auto de tu interés y además podrás financiar el resto. \n\n[Calcula tu crédito](/financiamiento-de-autos) en nuestra página.',
                q: '¿Aceptan mi auto a cuenta?'
            },
            {
                a: 'Todos nuestros autos cuentan con una garantía gratuita de 1 año, cuya cobertura incluye 11 sistemas del vehículo y más de 150 componentes. Aplica para vehículos seminuevos de hasta 10 años de antigüedad con un kilometraje por debajo de los 200 mil kilómetros.',
                q: '¿Sus autos cuentan con garantía?'
            },
            {
                a: 'Claro que sí, para que tomes la mejor decisión, ¡haz tu prueba de manejo sin compromiso! Puedes probarlo durante 7 días o 300 km y si no estás satisfecho, solicita la devolución de tu dinero.',
                q: '¿Puedo hacer prueba de manejo?'
            },
            {
                a: '¡Por supuesto! A través de nuestra División de Financiamiento Automotriz de vehículos seminuevos, ofrecemos soluciones flexibles para que cambies tu auto fácilmente con estos beneficios:\n- Crédito automotriz para autos de hasta 10 años de antigüedad\n- Plazos de 6 hasta 72 meses\n- Enganche desde el 15%\n- 5 opciones de seguro\n- Pre-aprobación automática en línea en función de historial crediticio\n- Contratación a través de firma electrónica',
                q: '¿Puedo comprar un auto a crédito con ustedes?'
            },
            {
                a: 'Los requisitos mínimos son:\n- Edad entre 18 y 74 años\n- Seguro -vida y vehículo- por el plazo adquirido\n- Localizador satelital (GPS) en caso de robo\n- Domiciliación mensual\n- Análisis de crédito\n- Entregar la documentación requerida',
                q: '¿Cuáles son los requisitos para tramitar un crédito automotriz?'
            },
            {
                a: '_Persona física:_\n- Identificación oficial vigente (INE, IFE, pasaporte, FM2 o FM3 en caso de extranjeros)\n- Recibos de nómina de los últimos 3 meses\n- Comprobante de domicilio no mayor a tres meses (Luz, agua, predial o teléfono)\n_Persona física con actividad empresarial:_\n- Identificación oficial vigente (INE, IFE o pasaporte)\n- Comprobante de domicilio no mayor a tres meses (Luz, agua, predial o teléfono)\n- Última declaración anual y declaraciones parciales\n- Estados de cuenta completos (últimos 3 meses)\n_Independiente:_\n- Identificación oficial vigente (INE, IFE o pasaporte)\n- Comprobante de domicilio no mayor a tres meses (Luz, agua, predial o teléfono)\n- Estados de cuenta completos (últimos 3 meses)',
                q: '¿Qué documentos necesito para tramitar mi crédito?'
            },
            {
                a: 'Llámanos al [800 022 2886](tel:+8000222886), mándanos un mensaje por whatsapp al [55 7888 7881](https://wa.me/5578887881) o escríbenos a contacto-mx@olxautos.com y con mucho gusto te atenderemos.\nTambién podemos atenderte vía inbox en nuestras redes sociales, encuéntranos en Twitter, Facebook e Instagram como @OlxAutosMexico',
                q: '¿Cómo puedo ponerme en contacto con ustedes?'
            },
            {
                a: '$5,000 MXN.',
                q: '¿Cuál es el monto mínimo para poder apartar un auto?'
            },
            {
                a: 'Sí, en OLXmobbi, te damos el reembolso al 100%.',
                q: '¿Me hacen la devolución de mi dinero de apartado al 100%?'
            },
            {
                a: 'Recuerda que debes enviar un correo a contacto-mx@olxautos.com en donde nos incluyas los siguientes documentos : Identificación oficial vigente, Comprobante de pago, y en dado caso en que tu pago fuera con transferencia deberás enviar tu estado de cuenta sin movimientos para poder validar la cuenta de depósito.',
                q: '¿Qué debo hacer para solicitar mi devolución ?'
            },
            {
                a: '5 días hábiles es el proceso directamente con OLXmobbi una vez que recibamos todos los documentos para poder realizar tu devolución. Una vez cerrado este proceso, la acreditación de tu dinero dependerá del tiempo de tu institución financiera.',
                q: '¿Cuánto tiempo tardan en hacer la devolución de mi pago por reserva en caso de no completar el proceso de compra?'
            },
            {
                a: 'Si, es posible cambiar tu reserva para otro modelo.',
                q: '¿Puedo utilizar el monto de mi reserva para otro auto?'
            },
            {
                a: 'El reembolso de tu dinero se deberá realizar siempre con el mismo método de pago que tú realizaste.',
                q: '¿Me pueden hacer el reembolso de mi dinero en efectivo?'
            }
        ],
        notFoundTitle: 'No se han encontrado resultados',
        notFoundSubtitle: 'No hemos encontrado resultados para "searchValue". Intente con una búsqueda diferente o intente reformular su pregunta.'
    },
    olxcl: {
        title: 'Preguntas frecuentes',
        notFoundTitle: 'No se han encontrado resultados',
        notFoundSubtitle: 'No hemos encontrado resultados para "searchValue". Intente con una búsqueda diferente o intente reformular su pregunta.',
        showTabs: false,
        content: [
            {
                q: '¿Quiénes somos?',
                a: `En OLXmobbi nos dedicamos a la compra y venta de autos usados.Con nosotros puedes vender, comprar y financiar tu auto de manera rápida, transparente y segura.

Nuestra plataforma se encuentra en Estados Unidos, México, Colombia, Chile, Argentina, India, Indonesia, Nigeria y Pakistán, con transacciones de más de 130 mil autos por año.`
            },
            {
                q: '¿Cómo recibo mi pago?',
                a: 'Tu pago se transferirá directamente a la cuenta bancaria que tú nos indiques. Por tu seguridad y nuestro proceso transparente, no realizamos pagos en efectivo.'
            },
            {
                q: '¿Cómo funciona la evaluación del auto?',
                a: `Realiza la tasación de tu auto de forma online y agenda tu inspección en una de nuestras sucursales ó puedes realizar la Evaluación Online y adelantar el proceso.

Un ejecutivo te contactará para confirmar tu cita en punto ó para ayudarte a agendar a la hora y el lugar que mejor te convenga.

Asistes a tu cita, confirmamos un precio estimado y realizamos la inspección de 240 puntos de tu auto. Con esto, te entregamos una oferta final. Si aceptas, recibes el pago el mismo día.`
            },
            {
                q: '¿Qué documentos necesitan para comprar mi auto?',
                a: 'Debes tener el padrón del auto, copias de las llaves, permiso de circulación, registro de mantenciones, revisión técnica, cédula de identidad o poder notarial, SOAP y el kit de emergencia.'
            },
            {
                q: '¿Dónde están ubicados?',
                a: `**Santiago:**

OLXmobbi Vitacura. Av. Vitacura 7081. Vitacura

OLXmobbi Movicenter. Av. Américo Vespucio Norte 1155. Huechuraba

OLXmobbi Carshopping. Av. Americo Vespucio Express 2451. Cerrillos

OLXmobbi Cerrillos. Av. Pedro Aguirre Cerda 5037. Cerrillos

OLXmobbi Vicuña Mackenna. Av. Vicuña Mackenna 8603. La Florida

OLXmobbi Autoshopping. Av. Departamental 4500. Macul

**Concepción:**

OLXmobbi Paicavi 1. Av. Paicavi 2121. Concepción

OLXmobbi Paicavi 2. Av. Paicavi 2155. Concepción

**Temuco:**

OLXmobbi Temuco 1. O’Higgins 0719. Temuco

**Los Ángeles:**

OLXmobbi Los Ángeles. Av. Alemania 410. Los Ángeles

**Talca:**

OLXmobbi Talca. Avenida 2 Norte 2970. Talca

**Valdivia:**

OLXmobbi Valdivia. Sevilla 204. Valdivia

**La Serena:**

Mall Puerta del Mar, Avda. Francisco de Aguirre 02. La Serena.

Si tienes alguna duda, ponte en contacto con nosotros al teléfono(56) 2 32626080. O bien, escríbenos a [contacto.cl@olxautos.com](mailto:contacto.cl@olxautos.com)`
            },
            {
                q: '¿Te dedicas a la compraventa de autos usados? ¡Te estamos buscando!',
                a: `Para los profesionales dedicados a la compra - venta de autos, tenemos una [plataforma especializada](https://dealer.olxautos.cl/). ¡Regístrate sin costo y recibe más beneficios para tu negocio!

Para mayor información puedes escribirnos a [dealerzone_cl@olxautos.com](mailto:dealerzone_cl@olxautos.com)`
            },
            {
                q: '¿Compran mi auto aunque tenga prenda?',
                a: `Sí, aceptamos autos que estén en prenda, pero el monto de la deuda se descontará de la oferta final. Recuerda que debes acudir con el certificado de prepago actualizado, emitido por la entidad que financió tu auto con vigencia de 5 días desde la venta del vehículo.

Importante: No aceptamos prepagos de Falabella, Migrantes, Scotiabank, Crediautos, ni de automotoras.`
            },
            {
                q: '¿Compran autos con documentos vencidos (permiso de circulación, revisión técnica) y/o deudas de tag ?',
                a: `Claro, puedes vender el vehículo aún teniendo estos documentos vencidos o atrasados. Es importante contar con el último documento que hayas obtenido.

Claro, puedes vender el vehículo aún teniendo estos documentos vencidos o atrasados. Es importante contar con el último documento que hayas obtenido.
En caso de tener deudas de multas, el monto de la deuda se descontará de la oferta final. El máximo se evalua según monto a pagar.`
            },
            {
                q: '¿Aceptan autos con restricción de Zona Franca?',
                a: 'Lamentablemente, no podemos aceptar vehículos que estén bajo restricción de Zona Franca.'
            },
            {
                q: '¿Aceptan autos de importación directa?',
                a: 'Si, aceptamos vehículos de importación directa. Escríbenos a [contacto.cl@olxautos.com](mailto:contacto.cl@olxautos.com)'
            },
            {
                q: '¿Compran motos?',
                a: 'No, no compramos motos. Nos dedicamos únicamente a la compra de autos usados'
            },
            {
                q: '¿Compran cualquier auto, sin importar el año?',
                a: `Sí, compramos todo tipo de autos a partir del 2012 en adelante o con kilometraje menor a 120.000 km; sin importar marca, modelo o versión.

Si tu auto no cumple con esos parámetros, podemos ofrecerte una Oferta en Vivo, donde publicaremos tu auto en nuestras plataformas online donde lo ofrecemos a una lista de 200 dealers aliados.

Importante: Nuestra oferta final dependerá del diagnóstico que se realice en la inspección a tu vehículo.`
            },
            {
                q: '¿Aceptan autos que hayan sido trabajados como taxi o Uber?',
                a: 'Sí, recibimos autos que hayan sido trabajados como taxis o Uber. En el caso de los taxis, estos deben haber sido pasados a particular y su autorización debe estar caducada de forma definitiva en el Registro Nacional de Transporte Público.'
            },
            {
                q: 'Mi auto tiene defectos mecánicos o estéticos, ¿aún así lo compran?',
                a: 'Lo compraremos solo si tiene detalles estéticos y está en buenas condiciones, sin daños estructurales ni problemas mecánicos mayores.'
            },
            {
                q: '¿En qué se basan para hacer sus cotizaciones?',
                a: 'Para brindarte la mejor oferta posible, en OLXmobbi desarrollamos un algoritmo de inteligencia, basado en información real del mercado, las condiciones reales de cada auto y el nivel de nuestro inventario.'
            },
            {
                q: '¿Cómo puedo ponerme en contacto con ustedes?',
                a: `Si tienes alguna duda sobre el proceso de venta, compra o financiamiento de un auto, nos puedes llamar al (56) 2 3262 6080 o escribirnos vía Whatsapp al número (56) 9 3782 8448. Tambien puedes escribirnos a [contacto.cl@olxautos.com](mailto:contacto.cl@olxautos.com).

Si tienes algún reclamo, contáctanos a nuestro canal de post venta: [postventa@olxautos.com](mailto:postventa@olxautos.com)

Si tienes dudas acerca de tu crédito con OLXmobbi, nuestro equipo te atenderá vía email: [sac.chile@olxautos.com](mailto:sac.chile@olxautos.com)`
            },
            {
                q: 'Quiero comprar un auto con ustedes, ¿dónde puedo verlos?',
                a: `**Santiago:**

OLXmobbi Vitacura. Av. Vitacura 7081. Vitacura

OLXmobbi Movicenter. Av. Américo Vespucio Norte 1155. Huechuraba

OLXmobbi Carshopping. Av. Americo Vespucio Express 2451. Cerrillos

OLXmobbi Cerrillos. Av. Pedro Aguirre Cerda 5037. Cerrillos

OLXmobbi Vicuña Mackenna. Av. Vicuña Mackenna 8603. La Florida

OLXmobbi Autoshopping. Av. Departamental 4500. Macul

**Concepción:**

OLXmobbi Paicavi 1. Av. Paicavi 2121. Concepción

OLXmobbi Paicavi 2. Av. Paicavi 2155. Concepción

**Temuco:**

OLXmobbi Temuco 1. O’Higgins 0719. Temuco

**Los Ángeles:**

OLXmobbi Los Ángeles. Av. Alemania 410. Los Ángeles

**Talca:**

OLXmobbi Talca. Avenida 2 Norte 2970. Talca

**Valdivia:**

OLXmobbi Valdivia. Sevilla 204. Valdivia

**La Serena:**

Mall Puerta del Mar, Avda. Francisco de Aguirre 02. La Serena.

Si tienes alguna duda, ponte en contacto con nosotros al teléfono (56) 2 32626080. O bien, escríbenos a [contacto.cl@olxautos.com](mailto:contacto.cl@olxautos.com)`
            },
            {
                q: '¿Puedo comprar un auto de forma online?',
                a: 'Claro, [ingresa a nuestro sitio web](/comprar-un-auto) y busca tu auto ideal. Podrás reservarlo y agendar una visita para conocerlo en persona. Ahi podrás realizar una prueba de manejo, solicitar financiamiento e incluso entregar tu antiguo auto como parte de pago.'
            },
            {
                q: '¿Puedo dejar mi auto en parte de pago?',
                a: '¡Sí! Trae tu auto para que lo tasemos mediante una inspección. Al finalizar te haremos una oferta; y si aceptas, ese dinero puedes abonarlo como pie a la compra de tu nuevo auto.'
            },
            {
                q: '¿Puedo comprar y financiar un auto en la misma sucursal?',
                a: 'Sí, contamos con opciones de financiamiento automotriz. [Simula tu crédito](/financiamiento-de-autos) en nuestro sitio web o con un ejecutivo de forma presencial, ¡será muy rápido y fácil!'
            },
            {
                q: '¿Puedo solicitar financiamiento 100% online?',
                a: `Si puedes! Completa los datos requeridos en nuestra página de financiamiento, y en menos de 1 minuto obtendrás la respuesta a tu solicitud de crédito.

De salir preaprobado, un ejecutivo te contactará y pedirá la documentación necesaria para validar la aprobación final de tu crédito, para luego finalizar con el proceso de firma.`
            },
            {
                q: '¿Qué requisitos debo cumplir para obtener el crédito automotriz?',
                a: `**Chilenos o extranjeros con permanencia definitiva o defintiiva en trámite:**

- Debes ser mayor de 25 y menor de 75 años.
- Abonar al menos un 30% del valor del auto como pie. También puedes entregar tu auto actual en parte de pago. ¡Nosotros te lo compramos!
- Mínimo de 6 meses de antigüedad laboral si eres dependiente y de 1 año si eres independiente.
- Renta líquida mínima de $550.000
- Que el auto que comprarás tenga máximo 7 años de antigüedad.
- Buen comportamiento comercial y de pago.
- No tener DICOM.`
            },
            {
                q: '¿Qué documentación necesito para la solicitud de mi crédito?',
                a: `**Para trabajadores Independientes:**

- Carpeta tributaria
- Copia de su cédula de identidad vigente (frente y dorso)
- Comprobante de domicilio (puede ser una boleta de servicio a tu nombre)
- 2 referencias personales de familiares, amigos o conocidos incluyendo: nombre completo, correo electrónico y número telefónico

**Para trabajadores Dependientes:**

- Últimas 3 liquidaciones de sueldo
- Últimas 12 cotizaciones de AFP
- Copia de su cédula de identidad vigente (frente y dorso)
- Comprobante de domicilio (puede ser una boleta de servicio a tu nombre)
- 2 referencias personales de familiares, amigos o conocidos incluyendo: nombre completo, correo electrónico y número telefónico`
            },
            {
                q: '¿Cuáles son los plazos de pago en que puedo solicitar mi financiamiento?',
                a: 'Nuestro financiamiento puede realizarse en 12, 18, 24, 30, 36, 42 ó 48 cuotas.'
            },
            {
                q: '¿Puedo comprar un auto con financiamiento a un particular?',
                a: `Sí, en OLXmobbi somos la única financiera del mercado que otorga créditos automotrices entre personas, sin necesidad de un concesionario de por medio.

Recuerda que inspeccionamos todos los autos, para asegurarle a nuestros clientes que están en buenas condiciones mecánicas, sin deudas, multas, entre otras.`
            },
            {
                q: '¿Dónde puedo pagar mi cuota mensual?',
                a: 'Ingresa directamente a nuestro [Portal de Pagos](https://pay.upago.cl/go/olx-autos-financiamiento-inmediato).'
            },
            {
                q: 'Quiero realizar el pago completo de mi crédito, ¿cómo lo puedo hacer?',
                a: 'Si quieres pagar completamente tu crédito, necesitamos que completes nuestra [Solicitud de Prepago](https://docs.google.com/forms/d/e/1FAIpQLSdpYsGBwqFRR0o68cYbuDi7g9N-0jZyYY5DOaqUv1DUr-L3rg/viewform).'
            },
            {
                q: 'Tengo dudas sobre mi crédito, ¿con quién puedo conversar?',
                a: 'Puedes escribirnos vía Whatsapp al +56 9 7472 7132 y responderemos con gusto todas tus dudas.'
            },
            {
                q: '¿Qué debo hacer luego de realizar mi reserva?',
                a: 'Debo esperar que se comunique un ejecutivo comercial a mi teléfono y/o email. Él me indicará el día y hora para asistir al punto de venta para completar mi compra.'
            },
            {
                q: 'Me arrepentí del vehículo que reservé, ¿Puedo solicitar un reembolso?',
                a: `Si, el monto de la reserva es 100% reembolsable. Si te arrepentiste y necesitas tu dinero de vuelta puedes solicitarlo directamente con el ejecutivo que te atendió.

Tu reembolso será procesado en un plazo de 5 a 7 días hábiles a partir de la fecha de solicitud del reembolso y será cargado directamente a tu cuenta.

Importante: el reembolso se realizará a la misma persona que realizó el pago de la reserva. No reembolsamos a terceros.`
            },
            {
                q: '¿Puedo comprar el vehículo que reservé con financiamiento OLX?',
                a: 'Si, es posible comprarlo con el financiamiento automotriz de OLXmobbi, sin embargo, su aplicación depende 100% de la situación crediticia del cliente. Existen algunos requisitos comerciales que deben ser cumplidos para solicitar financiamiento en OLXmobbi.'
            },
            {
                q: '¿Puedo dejar un vehículo en parte de pago para completar la compra del vehículo que reservé a través de www.olxautos.cl ?',
                a: 'Si, puedes dejar tu vehículo en parte de pago, siempre y cuando aplique a las políticas comerciales establecidas por OLXmobbi. De aplicar, debe ser inspeccionado y tasado por nuestros especialistas. Finalmente, tu ejecutivo comercial te comunicará el precio por el cual OLXmobbi podría comprar tu vehículo y te guiará en el proceso para llevar a cabo.'
            }
        ],
        page: {
            finance: [
                {
                    q: '¿Puedo solicitar financiamiento 100% online?',
                    a: `Si puedes! Completa los datos requeridos en nuestra página de financiamiento, y en menos de 1 minuto obtendrás la respuesta a tu solicitud de crédito.

                    De salir preaprobado, un ejecutivo te contactará y pedirá la documentación necesaria para validar la aprobación final de tu crédito, para luego finalizar con el proceso de firma.`,
                    category: 'Financiar'
                },
                {
                    q: '¿Qué requisitos debo cumplir para obtener el crédito automotriz?',
                    a: '- Debes ser mayor de 25 años\n - Abonar mínimo un 20% del valor del auto como pie. También puedes entregar tu auto actual en parte de pago. ¡Nosotros te lo compramos! \n- Mínimo de 6 meses de antigüedad laboral si eres dependiente y de 1 año si eres independiente \n- Renta mínima de $550.000 \n- Que el auto que comprarás sea mayor a 2014 \n- Buen comportamiento comercial y de pago \n\n Para extranjeros: \n- Renta Mínima: $550.000 \n- Año del Auto mínimo: 2014 si es usado. También financiamos autos nuevos. \n- Pie Mínimo: 20% \n- Sin DICOM \n- 6 meses de antigüedad laboral (dependiente) 1 año si eres independiente \n- Residencia definitiva o en trámite. \n- Mayor de 25 años y menor de 75 \n- Capacidad de pago',
                    category: 'Financiar'
                },
                {
                    q: '¿Qué documentación necesito para la solicitud de mi crédito?',
                    a: 'Para trabajadores Independientes:\n - Copia de su cédula de identidad (frente y dorso) \n- Carpeta tributaria \n- 2 Referencias personales de conocidos incluyendo: Nombre completo, RUT, correo electrónico y número telefónico \n- Comprobante de domicilio (puede ser una factura de servicio a tu nombre)\n\n Para trabajadores Dependientes:\n - Copia de su cédula de identidad (frente y dorso) \n- Últimas 3 liquidaciones de sueldo \n- Últimas 12 cotizaciones de AFP  \n- 2 Referencias personales de conocidos incluyendo: Nombre completo, RUT, correo electrónico y número telefónico \n- Comprobante de domicilio (puede ser una factura de servicio a tu nombre)',
                    category: 'Financiar'
                },
                {
                    q: '¿Cuáles son los plazos de pago en que puedo solicitar mi financiamiento?',
                    a: 'Nuestro financiamiento puede realizarse en 12, 24, 36 y 48 cuotas.',
                    category: 'Financiar'
                },
                {
                    q: '¿Puedo dejar mi auto en parte de pago?',
                    a: '¡Sí! Trae tu auto para que lo tasemos mediante una inspección. Al finalizar te haremos una oferta; y si aceptas, ese dinero puedes abonarlo como pie a la compra de tu nuevo auto.',
                    category: 'Financiar'
                },
                {
                    q: '¿Puedo comprar y financiar un auto en la misma sucursal?',
                    a: 'Claro, contamos con opciones de financiamiento automotriz. [Simula tu crédito](/financiamiento-de-autos) en nuestro sitio web o con un ejecutivo de forma presencial, ¡será muy rápido y fácil!',
                    category: 'Financiar'
                },
                {
                    q: '¿Puedo comprar un auto con financiamiento a un particular?',
                    a: 'Sí, en OLX AUTOS somos la única financiera del mercado que otorga créditos automotrices entre personas, sin necesidad de un concesionario de por medio.\n\n Recuerda que inspeccionamos todos los autos, para asegurarle a nuestros clientes que están en buenas condiciones mecánicas, sin deudas, multas, entre otras.',
                    category: 'Financiar'
                },
                {
                    q: '¿Dónde puedo pagar mi cuota mensual?',
                    a: 'Te recomendamos ingresar directamente a nuestro [Portal de Pagos](https://pay.upago.cl/go/olx-autos-financiamiento-inmediato).',
                    category: 'Financiar'
                },
                {
                    q: 'Quiero realizar el pago completo de mi crédito, ¿cómo lo puedo hacer?',
                    a: 'Si quieres pagar completamente tu crédito, necesitamos que completes una [Solicitud de Prepago](https://docs.google.com/forms/d/e/1FAIpQLSdpYsGBwqFRR0o68cYbuDi7g9N-0jZyYY5DOaqUv1DUr-L3rg/viewform).',
                    category: 'Financiar'
                },
                {
                    q: 'Tengo dudas sobre mi crédito, ¿con quién puedo conversar?',
                    a: 'Puedes escribirnos directamente a [sac.chile@olxautos.com](mailto:sac.chile@olxautos.com) y responderemos con gusto todas tus dudas.',
                    category: 'Financiar'
                }
            ]
        }
    },
    olxco: {
        title: 'Preguntas frecuentes',
        notFoundTitle: 'No se han encontrado resultados',
        notFoundSubtitle: 'No hemos encontrado resultados para "searchValue". Intente con una búsqueda diferente o intente reformular su pregunta.',
        showTabs: false,
        tabs: [
            { categoryId: 'c2b', title: 'Vender auto' },
            { categoryId: 'b2c', title: 'Comprar auto' },
            { categoryId: 'financial', title: 'Financiar' }
        ],
        content: [
            {
                q: '¿Quiénes somos?',
                a: 'Somos OLXmobbi, una plataforma global de compra, venta y financiamiento de automóviles usados que está transformando la industria automotriz, de forma más simple, directa y con un proceso innovador en línea, el cual se complementa con consultoría personal para realizar un acompañamiento integral.\n'
                    + '\n'
                    + 'OLXmobbi tiene presencia global en más de 9 países. En América Latina llegamos a Argentina, Chile,  México y Colombia, donde tenemos puntos de atención en la ciudad de Bogotá. \n'
                    + 'Te asesoramos en www.olxautos.com.co o en el teléfono 6015520000  o vía  Whatsapp al 320 488 5389.',
                categoryId: 'c2b'
            },
            {
                q: '¿En dónde puedo vender mi auto?',
                a: 'Nuestro propósito es que cada colombiano pueda cambiar su auto de forma simple,  estamos en plan de expansión por todo Colombia. Actualmente puedes comprar, vender y financiar tu auto en Bogotá y Chía.',
                categoryId: 'c2b'
            },
            {
                q: '¿Por qué vender tu auto con OLXmobbi?',
                a: '1. Inicia tu proceso en línea.\n'
                    + '2. Cuentas con respaldo garantizado con una compañía de presencia global\n'
                    + '3. Nos encargamos de todos los trámites haciendo el proceso más rápido y seguro para tí.\n'
                    + '4. Más de 15.000 transacciones que demuestran la confianza depositada en nuestra marca.\n'
                    + '5. Contamos con presencia en Bogotá a través de múltiples salas de exhibición para venta, compra y financiamiento.\n'
                    + '6. Somos líderes en el mercado: contamos con la solidez y experiencia de OLX Group, nuestra empresa matriz, también líder mundial en la industria de clasificados.\n'
                    + '7. Más de 3 años de presencia en el mercado colombiano.\n',
                categoryId: 'c2b'
            },
            {
                q: '¿Cómo funciona el proceso de venta en OLXmobbi?',
                a: 'Vender tu carro con nosotros es muy fácil:\n'
                    + '\n'
                    + '\n'
                    + '1. Obtén un rango de valores de referencia de precio por tu auto en línea, ingresando la información en el formulario ubicado en la página principal de nuestra página web (www.olxautos.com.co).\n'
                    + '2. Continúa con la revisión en línea, te haremos unas preguntas sobre el estado físico de tu auto, ¡Es muy corto!.  Nuestros asesores se comunicarán contigo para hacerte una pre-oferta, si estás de acuerdo, agendamos una cita para firma de documentos y entrega de tu auto en el centro de atención más cercano.\n'
                    + '3. Recibirás el pago por tu auto a través de transferencia bancaria a tu cuenta, y así de rápido y seguro vendes con OLXmobbi.\n'
                    + '\n'
                    + '\n'
                    + 'Puedes conocer más información en la sección [términos y condiciones](https://statics.olx.com.co/olxco/autos/light/v1/documents/terms-and-conditions-co.pdf).',
                categoryId: 'c2b'
            },
            {
                q: '¿Cuál es el método de pago para mi auto?',
                a: 'Una vez que firmes la documentación correspondiente y entregues tu auto, el pago   se hará a través de transferencia electrónica de acuerdo con la negociación.\n'
                    + '\n'
                    + '\n'
                    + 'Ten presente que el reflejo del pago en la cuenta está sujeto a los tiempos de ciclos bancarios. Puedes solicitarnos el soporte de la transacción en nuestros centros de atención o vía WhatsApp al 320 488 5389.',
                categoryId: 'c2b'
            },
            {
                q: '¿Cómo generan el precio en línea?',
                a: 'Para generar el rango de valores de referencia nos basamos en múltiples fuentes de información del mercado de Autos usados. Adicionalmente, analizamos factores como: depreciación, kilometraje, precios de compra, precios de venta, fuentes de precios referentes en el sector, como publicaciones o aseguradoras, entre otros factores que pueden interferir en el valor comercial de un vehículo.\n'
                    + '\n'
                    + '\n'
                    + 'También, factores de demanda excepcionales, como reputación de modelos y referencias, volúmenes de venta, entre otros. Ten presente que el rango de valores de referencia / cotización / precio preliminar no constituye una oferta de compra. Este valor es un punto de partida y te servirá como referencia para decidir si continúas el proceso con nosotros o no.',
                categoryId: 'c2b'
            },
            {
                q: '¿En qué consiste la revisión de mi carro?',
                a: 'Tienes la opción de adelantar la revisión de tu auto en línea. Una vez hayas obtenido el rango de precios diligenciando el formulario ubicado en la página principal de nuestro sitio web, te aparecerá la opción __“Revisión en Línea”__, la cual consiste en contestar una serie de preguntas para obtener información de tu auto. Luego de responderlas, atiendes la llamada de uno de nuestros asesores para continuar el proceso y agendar una cita para firma de documentos y entrega de tu auto en uno de nuestros centros de atención.',
                categoryId: 'c2b'
            },
            {
                q: '¿Cómo generan la oferta para realizar la venta del vehículo?',
                a: 'Partiremos de la pre oferta que obtuviste al iniciar el proceso con la revisión del carro en línea, este precio se validará en la revisión física y podrá variar acorde al estado del auto de manera presencial vs la información que nos suministras en línea. La oferta  se generará una vez revisado el auto de forma física en alguno de nuestros centros de atención, con el resultado de la revisión del auto y factores como alistamientos, reparaciones, fluidos, kilometraje, accesorios, entre otros.\n'
                    + '\n'
                    + '\n'
                    + 'Ten presente que OLXmobbi se reserva el derecho de presentar o no una oferta, y esta puede NO SER IGUAL al precio preliminar en línea o a la pre-oferta entregada durante el proceso de “Revisión en Línea” por nuestros asesores comerciales de call center. También, se descontarán del pago las deudas asociadas al auto y el costo del trámite de traspaso, además se hará la liquidación de seguros.',
                categoryId: 'c2b'
            },
            {
                q: '¿Puedo vender mi auto si tengo multas o impuestos pendientes?',
                a: '¡Claro que sí! Nos encargamos de realizar el pago y gestión de las multas e impuestos. Recuerda que este valor se descontará del monto de la compra por tu auto, todo esto para hacerte el proceso más sencillo y que no pierdas tiempo en estos trámites.',
                categoryId: 'c2b'
            },
            {
                q: '¿Por cuánto tiempo estará vigente la oferta?',
                a: 'Puedes aceptar o rechazar la oferta en el momento en que te la entregamos. Si no la aceptas, y decides tomarte un tiempo para pensarlo, cuando retornes a nuestros centros de atención para vender tu auto, lo revisaremos de nuevo para validar su estado. Recuerda que la oferta final podría ser diferente a la anteriormente entregada.\n'
                    + '\n'
                    + '\n'
                    + 'Ten en cuenta que los términos y condiciones definitivos de la compra serán los que se plasmen al momento de firmar los documentos en nuestros centros de atención.',
                categoryId: 'c2b'
            },
            {
                q: '¿Cuáles son los documentos o accesorios necesarios para realizar la venta y entregar el vehículo en nuestros centros de atención?',
                a: '- Cédula de ciudadanía/ Documento de identidad.\n'
                    + '- Tarjeta de propiedad original.\n'
                    + '- Certificado de revisión técnico-mecánica (si aplica)\n'
                    + '- SOAT original.\n'
                    + '- Certificación bancaria para realizar la transferencia del pago.\n'
                    + '- Duplicado de la llave del carro.\n'
                    + '- Recibos de pago de impuestos.\n'
                    + '- En caso de prenda presentar la proyección de la deuda asociada al vehículo con proyección a 3 días hábiles contados a partir del día siguiente hábil a la cita de la revisión física o el paz y salvo original con antigüedad de 30 días o carta de levantamiento de prenda.\n'
                    + '- Manual del vehículo (opcional).\n'
                    + '- Equipamiento del carro como llanta de repuesto, herramienta, tapetes, extintor, kit de primeros auxilios, radio, perno de seguridad para las llantas y otros si aplica según el inventario.\n'
                    + '- Dependiendo del caso, puede haber documentos adicionales, tales como: certificados de deudas bancarias, antecedentes judiciales, historial de cumplimiento de la garantía del fabricante, entre otros.',
                categoryId: 'c2b'
            },
            {
                q: '¿Cuáles son los documentos que debes firmar en la oficina al momento de vender tu carro?',
                a: '- Formato de autorización de inspección y vinculación.\n'
                    + '- Contrato de compraventa de OLXmobbi.\n'
                    + '- FUN (Formato Único Nacional de Traspaso).\n'
                    + '- Contrato de mandato.\n'
                    + '- Pagaré y carta de instrucciones.\n'
                    + '- Formato de autorización de transferencia.',
                categoryId: 'c2b'
            },
            {
                q: '¿Por qué debo firmar el pagaré?',
                a: 'Este documento nos permite cubrir alguna eventualidad anterior o posterior a la venta del auto como: embargos, impuestos no pagados en su totalidad, actos administrativos mal realizados, pendientes judiciales, información desactualizada en las bases de datos de entidades oficiales, entre otras que no podamos identificar o que no hayan sido informados durante el proceso de venta. \n'
                    + '\n'
                    + '\n'
                    + 'Podrás solicitar la devolución del pagaré 45 días hábiles después de culminado el trámite de traspaso. Al momento de la venta, nuestro equipo te brindará mayor información.\n'
                    + '\n'
                    + '\n'
                    + 'Puedes consultar el estado del trámite de traspaso ante la autoridad competente, en este caso el Registro Único Nacional de Tránsito (RUNT).',
                categoryId: 'c2b'
            },
            {
                q: '¿Puedo vender cualquier tipo de vehículo?',
                a: `Compramos cualquier carro liviano de servicio particular, como automóviles, camperos, camionetas, pick up y SUV.
No puedes vender con nosotros vehículos que:
- Presten o hayan prestado servicio público y requieran un seguro contractual.
- Vehículos de carga de capacidad superior a una (1) tonelada.
- Vehículos con adaptaciones NO originales a la marca.
- Vehículos a gas o nitrógeno.
- Vehículos con responsabilidad civil superior a $40.000.000 de pesos colombianos
- Vehículos diplomáticos o que hayan sido ingresados al país por dicha modalidad.
- Vehículos que hayan sido o sean blindados u oficiales.
- Vehículos clásicos.
- Vehículos con leasing.
- Vehículos declarados pérdida total por daño (PTD) o pérdida total por hurto (PDH) (Con o sin reparaciones)
- Vehículos importados directamente.
- Vehículos con sistemas de identificación adulterados.
- Vehículos inmunizados.
- Vehículos con procesos judiciales activos o limitaciones a la propiedad
- Vehículos matriculados en ciudades específicas (para más información ver términos y condiciones)
- Vehículos con deudas iguales o superiores al 70% del precio preliminar entregado.
                `,
                categoryId: 'c2b'
            },
            {
                q: '¿Puedo vender autos con ustedes anteriores al año modelo 2010?',
                a: 'En ese caso debes consultarnos directamente a servicioalcliente_CO@olxautos.com o llamarnos al 601 552 0000 para ver evaluar la viabilidad de la compra\n'
                    + '\n'
                    + '\n'
                    + '__Mi auto tiene algunos problemas estéticos o mecánicos, aún así, ¿puedo venderlo con ustedes?__\n'
                    + '\n'
                    + '\n'
                    + 'Sí. Descontaremos el valor de las afectaciones encontradas en tu auto al establecer la oferta de compra. No revisamos ni compramos autos que lleguen en grúa.',
                categoryId: 'c2b'
            },
            {
                q: '¿Puedo vender mi auto con ustedes, aunque tenga prenda, pignoraciones o deudas en general?',
                a: 'Puedes vender tu auto con nosotros si tiene deudas, siempre y cuando estas no superen el 70% del precio preliminar que te dimos al iniciar el proceso. El monto total se descontará del pago y primero pagaremos las deudas financieras, no haremos anticipos de saldo, y una vez nos hayan notificado el registro del levantamiento de la prenda, y se haya realizado el trámite del traspaso, te pagaremos el saldo restante a tu cuenta bancaria.',
                categoryId: 'c2b'
            },
            {
                q: '¿Quiénes somos?',
                a: 'Somos OLXmobbi, una plataforma global de compra, venta y financiamiento de automóviles usados que está transformando la industria automotriz, de forma más simple, directa y con un proceso innovador en línea, el cual se complementa con consultoría personal para realizar un acompañamiento integral.\n'
                    + '\n'
                    + 'OLXmobbi tiene presencia global en más de 9 países. En América Latina llegamos a Argentina, Chile,  México y Colombia, donde tenemos puntos de atención en la ciudad de Bogotá. \n'
                    + '\n'
                    + 'Te asesoramos en www.olxautos.com.co o en el teléfono 6015520000 opción 2 o vía  Whatsapp al 320 488 5389.',
                categoryId: 'b2c'
            },
            {
                q: '¿Dónde puedo ver los carros que tiene para la venta?',
                a: 'Hay 4 maneras de comprar tu auto con nosotros:\n'
                    + '\n'
                    + '1. Conoce nuestro inventario directamente en [nuestra página web](https://www.olxautos.com.co/comprar-un-auto)\n'
                    + '2. Nuestro equipo te brinda asesoría telefónica, llámanos al 6015520000 opción 2 o vía  Whatsapp al 320 488 5389.\n'
                    + '3. Compra en nuestra tienda en [línea de Facebook](https://www.facebook.com/olxautoscolombia/shop/?referral_code=page_shop_tab&preview=1).\n'
                    + '4. Acercarte directamente a nuestros puntos de exhibición en Bogotá:\n'
                    + '\n'
                    + '- Sala 127 Niza. Avenida calle 127 # 48-21. Esquina. \n'
                    + '- Sala 102. Calle 102A # 45-05\n'
                    + '- Sala Jumbo Cra 30. Av NQS #18a-2\n',
                categoryId: 'b2c'
            },
            {
                q: '¿Cómo sé cuál es el estado real del auto que quiero comprar?',
                a: 'Todos nuestros autos pasan por una inspección minuciosa de más de 240 puntos, que considera la parte estética, mecánica y documental; todo se traduce en una ficha técnica donde verás con total transparencia el estado real del auto. Puedes solicitar un peritaje externo o traer tu mecánico de confianza.',
                categoryId: 'b2c'
            },
            {
                q: '¿Cuentan con garantía?',
                a: 'Nuestros vehículos cuentan con garantía gratuita de 6 meses o 5.000 Kilómetros, lo que ocurra primero luego de la venta del vehículo. La cobertura incluye piezas de los sistemas de: motor, transmisión y sistema de tracción, frenos, refrigeración, dirección, embrague y aire acondicionado.\n'
                    + '\n'
                    + 'Los detalles de la garantía serán plasmados en los documentos de compra del vehículo.\n'
                    + '\n'
                    + 'Aplican términos y condiciones que podrás consultar en [términos y condiciones](https://statics.olx.com.co/olxco/autos/light/v1/documents/terms-and-conditions-co.pdf)',
                categoryId: 'b2c'
            },
            {
                q: '¿Puedo hacer prueba de satisfacción?',
                a: 'Claro que sí, puedes probarlo durante 7 días o 300 km y si no estás satisfecho, solicita la devolución de tu dinero.Te facilitamos la vida, ¿no?\n'
                    + '\n'
                    + 'Aplican términos y condiciones que podrás consultar en [términos y condiciones](https://statics.olx.com.co/olxco/autos/light/v1/documents/terms-and-conditions-co.pdf)',
                categoryId: 'b2c'
            },
            {
                q: '¿Se acepta mi auto como parte de pago para comprar uno con ustedes?',
                a: 'Sí, nuestros expertos harán una inspección de tu vehículo y si cumple con nuestros estándares mecánicos y funcionales, te daremos una oferta de compra,  esta se descontará del precio final del auto de tu interés. Recuerda que puedes financiar el saldo con nuestros planes de financiación directos. ¡Todo desde un solo lugar!',
                categoryId: 'b2c'
            },
            {
                q: '¿Puedo comprar y financiar un auto en la sala de ventas?',
                a: 'Si, una de las ventajas de cambiar tu auto con nosotros es que puedes vender tu auto, elegir el próximo y si necesitas financiarlo desde un solo lugar, esto hace que el proceso sea mucho más rápido y fácil.',
                categoryId: 'b2c'
            },
            {
                q: '¿Los trámites, traspasos y documentos quién los debe realizar?',
                a: '¡No te preocupes! De estos procesos en OLXmobbi nos encargamos y te entregamos todos los documentos de tu auto a tu nombre.',
                categoryId: 'b2c'
            },
            {
                q: '¿Cuánto tiempo dura el proceso de compra de un auto con OLXmobbi?',
                a: '¡Es muy rápido! En promedio son de 7 a 10 días hábiles, esto dependerá también de la forma de pago que escojas y la rapidez con que trámites la solicitud de financiamiento en caso de necesitarlo. Recuerda que los tiempos de traspaso dependen directamente de las entidades encargadas.',
                categoryId: 'b2c'
            },
            {
                q: '¿Qué papeles me entregan cuando yo compro un carro?',
                a: 'Te entregamos el certificado de garantía, SOAT, exoneración de responsabilidad en caso de que no tengamos la tarjeta de propiedad todavía lista, el documento de la Súper Industria y Comercio (Guía sobre garantías para vehículos usados de servicio particular), y revisión técnico mecánica, si el auto ya lo requiere.',
                categoryId: 'b2c'
            },
            {
                q: '¿Cómo puedo apartar o pisar un vehículo? ¿Recién algún valor para reservarlo?',
                a: 'La separación de tu auto se hace desde $500.000 o más para consolidar la separación. Puedes pagar en efectivo, por transferencia bancaria o en nuestras vitrinas con datáfono.',
                categoryId: 'b2c'
            },
            {
                q: '¿Tengo que ir a recoger el carro que compre o ustedes lo llevan a mi domicilio?',
                a: 'Por el momento no tenemos servicio a domicilio, pronto te contaremos más noticias :)',
                categoryId: 'b2c'
            },
            {
                q: '¿Cómo sé que el kilometraje del vehículo que ustedes venden no está adulterado?',
                a: 'Todos nuestros autos pasan por una inspección minuciosa de más de 240 puntos, que considera la parte estética, mecánica y documental; todo se traduce en una ficha técnica donde verás con total transparencia el estado real del auto.\n'
                    + 'Puedes solicitar un peritaje externo o traer tu mecánico de confianza.',
                categoryId: 'b2c'
            },
            {
                q: '¿En qué ciudades están disponibles para comprar un carro?',
                a: 'Por el momento solo nos encontramos en Bogotá, con planes de expansión a muchas partes más de Colombia.',
                categoryId: 'b2c'
            },
            {
                q: '¿Los precios de los vehículos, son el valor final o se puede negociar ese valor?',
                a: 'Todo depende del auto, pero siempre estamos abiertos a ayudarte. Acércate a nuestras salas de exhibición o comunícate con nuestro asesor para encontrar el auto que se ajuste a tu presupuesto.',
                categoryId: 'b2c'
            },
            {
                q: '¿Solo venden vehículos usados?',
                a: 'Si, solo nos dedicamos a la comercialización de vehículos usados. Pero podrás encontrar modelos muy recientes del mercado, todos nuestros autos pasan por una inspección minuciosa de más de 240 puntos, que considera la parte estética, mecánica y documental; todo se traduce en una ficha técnica donde verás con total transparencia el estado real del auto.\n'
                    + 'Puedes solicitar un peritaje externo o traer tu mecánico de confianza.',
                categoryId: 'b2c'
            },
            {
                q: '¿Me entregan el vehículo lavado y con buen olor?',
                a: 'Esperamos que te guste mucho tu próximo auto, al momento de comprarlo nos encargamos de que tengas la mejor entrega al finalizar el proceso, donde limpiamos el vehículo y lo entregamos en las mejores condiciones.',
                categoryId: 'b2c'
            },
            {
                q: '¿Qué métodos de pago reciben?',
                a: 'Datafono, tarjeta de crédito o débito, transferencia bancaria, crédito, retoma, leasing y efectivo (máximo hasta $10.000.000 por negocio).',
                categoryId: 'b2c'
            },
            {
                q: '¿Quiénes somos?',
                a: 'Somos OLXmobbi, una plataforma global de compra, venta y financiamiento de automóviles usados que está transformando la industria automotriz, de forma más simple, directa y con un proceso innovador en línea, el cual se complementa con consultoría personal para realizar un acompañamiento integral.\n'
                    + '\n'
                    + 'OLXmobbi tiene presencia global en más de 9 países. En América Latina llegamos a Argentina, Chile, México y Colombia, donde tenemos puntos de atención en la ciudad de Bogotá. \n'
                    + '\n'
                    + 'Te asesoramos en www.olxautos.com.co o en el teléfono 6015520000  o vía  Whatsapp al 3204885389.',
                categoryId: 'financial'
            },
            {
                q: '¿Qué características tiene el crédito OLXmobbi?',
                a: 'Nuestro crédito vehicular tiene los siguientes beneficios:\n'
                    + '- Financiamiento sin intermediarios.\n'
                    + '- Préstamos hasta del 95% del valor del auto.\n'
                    + '- Financiamos desde $5.000.000 con tasa y cuota fija hasta en 72 meses de plazo.\n'
                    + '- Financiamos autos de hasta 11 años de antigüedad y hasta 13 años en autos que\n'
                    + 'adquieras en vitrinas de OLXmobbi.',
                categoryId: 'financial'
            },
            {
                q: '¿Cómo es el proceso de solicitud de crédito?',
                a: 'Debes seguir los siguientes 3 pasos:\n'
                    + '1. Ingresa al simulador y diligencia el formulario de datos para ser pre aprobado en línea.\n'
                    + '2. En las siguientes 24 horas hábiles, uno de nuestros ejecutivos te contactará para solicitarle los documentos respectivos.\n'
                    + '3. ¡Y listo tendrás tu crédito aprobado!\n'
                    + '\n'
                    + 'Durante todo ese tiempo te acompañaremos resolviendo dudas e inquietudes.',
                categoryId: 'financial'
            },
            {
                q: '¿Qué porcentaje puedo financiar del valor del carro?',
                a: 'En OLXmobbi financiamos hasta el 95% de tu próximo vehículo si lo compras en nuestras salas de exhibición.',
                categoryId: 'financial'
            },
            {
                q: '¿Puedo hacer abonos extraordinarios a mi deuda?',
                a: '¡Claro! Hazlo a nuestra cuenta corriente N 637461013 de Banco de Bogotá o ingresa a nuestra página www.olxautos.com.co y realiza el abono que deseas.',
                categoryId: 'financial'
            },
            {
                q: '¿Cómo es el proceso de levantamiento de prenda?',
                a: 'Cuando realices el pago total de tu deuda puedes solicitar el levantamiento de prenda a servicioalclientefin@olxautos.com. Indicando tu número de teléfono, dirección y ciudad de envío.',
                categoryId: 'financial'
            },
            {
                q: '¿De cuánto es la cuota inicial mínima para comprar un auto usado?',
                a: 'La cuota inicial dependerá de muchas variantes del valor del crédito que selecciones o las cuotas mensuales. Pero mínimo deberás tener disponible el 5% del valor total del auto.',
                categoryId: 'financial'
            },
            {
                q: '¿Qué plazos maneja OLXmobbi?',
                a: 'Dependiendo de la capacidad de endeudamiento del cliente brindamos la posibilidad de financiar desde 18 cuotas mensuales para quienes desean pagar más rápido su crédito y hasta ¡72 cuotas! para aquellos que desean tener más facilidad de pago sin importar el modelo del vehículo. Igualmente damos la opción durante la vida del crédito de modificar los plazos a gusto del cliente según sus necesidades.',
                categoryId: 'financial'
            },
            {
                q: '¿El seguro se incluye en el financiamiento?',
                a: 'Los seguros se pueden incluir dentro de la cuota mensual y no generan pago de intereses.',
                categoryId: 'financial'
            },
            {
                q: '¿Dónde puedo contactarme para saber en qué estado va mi proceso?',
                a: 'Escríbenos a digital.financiamiento@olxautos.com y te responderemos lo antes\n'
                    + 'posible en horario hábil de lunes a viernes o en el teléfono 6015520000 opción 3  o vía  Whatsapp al 320 488 5389.\n',
                categoryId: 'financial'
            },
            {
                q: '¿Qué tipo de vehículos financia OLXmobbi?',
                a: 'Financiamos vehículos de hasta 11 años de antigüedad o hasta 13 años si son de nuestras vitrinas OLXmobbi.\n'
                    + '\n'
                    + 'Todos están sujetos a estudio de crédito, los autos deben ser para uso particular no productivo (no\n'
                    + 'comercial) y no prestamos para vehículos blindados, ni motos.  Los vehículos deben ser de fácil comercialización.',
                categoryId: 'financial'
            },
            {
                q: '¿Qué documentos solicitan para el estudio de crédito?',
                a: 'Si eres Empleado:\n'
                    + '- Solicitud de Crédito / Vinculación de OLX FIN COLOMBIA S.A.S. totalmente diligenciada, firmada y con huella.\n'
                    + '- Fotocopia de la cédula de ciudadanía. Ampliada al 150.\n'
                    + '- Carta laboral que especifique cargo, antigüedad, tipo de contrato y sueldo devengado.\n'
                    + '\n'
                    + 'Si eres Pensionado:\n'
                    + '- Solicitud de Crédito / Vinculación de OLX FIN COLOMBIA S.A.S. totalmente diligenciada, firmada y con huella.\n'
                    + '- Fotocopia de la cédula de ciudadanía. Ampliada al 150.\n'
                    + '- Últimos 2 comprobantes de pago de pensión.\n'
                    + '\n'
                    + '\n'
                    + 'Si eres trabajador Independiente:\n'
                    + '- Solicitud de Crédito / Vinculación de OLX FIN COLOMBIA S.A.S. totalmente diligenciada, firmada y con huella.\n'
                    + '- Fotocopia de la cédula de ciudadanía, ampliada al 150.\n'
                    + '- Soporte de ingresos\n'
                    + '- Camara y Comercio y/o RUT \n'
                    + '\n'
                    + '\n'
                    + 'Nota: Dependiendo del tipo de actividad, te solicitaremos documentos adicionales.',
                categoryId: 'financial'
            },
            {
                q: '¿El crédito es directamente con OLX o hay algún intermediario?',
                a: 'La financiación es directamente con OLXmobbi, no hay intermediarios en nuestro proceso.',
                categoryId: 'financial'
            },
            {
                q: 'Tengo una solicitud preaprobada, ¿qué sigue ahora?',
                a: '¡Felicitaciones! En las siguientes 24 horas hábiles te contactaremos para solicitar tus documentos, después nuestro equipo de análisis de crédito validará la información y emitirá una aprobación para que adquieras el carro de tus sueños. Si aún no has seleccionado un carro, revisa los que OLXmobbi tiene para ti.',
                categoryId: 'financial'
            },
            {
                q: '¿Mis datos personales están seguros?',
                a: '¡Por supuesto! Somos una compañía comprometida con la protección de tu información, te invitamos a leer la política de privacidad de OLXmobbi.',
                categoryId: 'financial'
            },
            {
                q: '¿Cómo hago el pago de mi cuota mensual?',
                a: 'Puedes pagar de forma online con tu número de cédula en nuestra página o de forma presencial, a nuestra cuenta corriente N 637461013 de Banco de Bogotá.',
                categoryId: 'financial'
            },
            {
                q: '¿Dónde puedo consultar el estado de mi crédito?',
                a: 'Mensualmente te enviaremos un correo electrónico con el estado de cuenta y detalles de saldo.',
                categoryId: 'financial'
            },
            {
                q: '¿Cómo puedo obtener un extracto de mi crédito o consultar la deuda a la fecha?',
                a: 'Mes a mes lo recibirás a tu correo electrónico. Si tienes otra duda puedes comunicarte al 6015520000 Opción 3 o solicita tu estado de cuenta escribiéndonos a servicioalclientefin@olxautos.com',
                categoryId: 'financial'
            },
            {
                q: '¿Cómo puedo modificar o recalcular el plazo de mi crédito?',
                a: 'Por favor escríbenos servicioalclientefin@olxautos.com para darte atención personalizada. Te recordamos que nuestro tiempo de respuesta es un plazo no mayor a 5 días hábiles.',
                categoryId: 'financial'
            },
            {
                q: '¿El financiamiento aplica también para reportados en Datacrédito?',
                a: '¡Bueno es que todos estamos reportados en Datacrédito! Hay algunos reportes positivos y otros no. Lo que nos diferencia de la mayoría de las entidades de financiamiento es que nosotros recibimos tu solicitud de crédito si tu reporte es por servicios de telecomunicaciones (ejemplo Movistar, Claro, cualquier compañía que te haya reportado por el pago de tu celular o servicio de internet).\n'
                    + '\n'
                    + '¡Claro eso sí, depende del caso! Pero existe la posibilidad.\n'
                    + '\n'
                    + 'Además, si no tienes vida crediticia, OLXmobbi te financia tu primer carro y da tu primer crédito.\n',
                categoryId: 'financial'
            }
        ],

        goBackBtnTitle: 'VOLVER'
    },
    olxar: {
        title: 'Preguntas frecuentes',
        notFoundTitle: 'No se han encontrado resultados',
        notFoundSubtitle: 'No hemos encontrado resultados para "searchValue". Intente con una búsqueda diferente o intente reformular su pregunta.',
        showTabs: true,
        tabs: [
            { categoryId: 'sell', title: 'Vendé tu auto' },
            { categoryId: 'buy', title: 'Comprá un auto' }
        ],
        content: [
            {
                q: '¿Qué es OLXmobbi?',
                a: 'Somos un sitio web que compra y vende autos usados. Lo hacemos de manera directa y transparente. Nuestro objetivo es transformar la manera en la que una persona vende y compra su auto usado, simplificando los procesos, asesorando con los trámites y brindando seguridad y comodidad en cada transacción.',
                categoryId: 'sell'
            },
            {
                q: '¿En qué ciudades están presentes?',
                a: `Actualmente tenemos oficinas comerciales en:

Buenos Aires: Av. Warnes 2707, Capital Federal

Buenos Aires: Shopping Norcenter, Esteban Echeverría 3750

Córdoba: Av. Colón 4551

Salta: Complejo office park, Av. Río de la Plata s/n, San Lorenzo

Mendoza: Lateral Sur del Acceso Este, M5521 Guaymallén`,
                categoryId: 'sell'
            },
            {
                q: '¿Cómo funciona?',
                a: `Podés vendernos tu auto en 3 simples pasos:

Paso 1: Cotizá online para conocer el rango estimado de valor de tu auto en el mercado con sólo 5 datos: marca, modelo, versión, año y kilometraje.

Paso 2: Evaluá a distancia tu auto ya sea mediante una autoevaluación online o con la ayuda de un asesor vía telefónica para obtener una oferta final por el mismo.

Paso 3: Entregá tu auto en la oficina comercial más cercana a tu ubicación, lo inspeccionamos y te damos el pago al instante por transferencia bancaria.`,
                categoryId: 'sell'
            },
            {
                q: 'No encuentro un Centro de Atención en mi ciudad, ¿Cómo puedo vender mi auto con ustedes?',
                a: 'Podés hacer el proceso de manera online desde cualquier parte del país y una vez que aceptás nuestra oferta deberás acercarte presencialmente a algunas de nuestras oficinas comerciales para hacer la entrega del mismo.',
                categoryId: 'sell'
            },
            {
                q: '¿Qué tipo de autos compran?',
                a: 'Compramos autos del año 2010 en adelante dependiendo de la marca y el modelo. Los mismos pueden estar radicados en cualquier parte del país. No compramos autos que hayan sido taxi ni remis o que estén prendados. Para vendernos el auto, debés ser el titular del mismo.',
                categoryId: 'sell'
            },
            {
                q: '¿Aceptan autos con prenda?',
                a: 'No, por políticas globales de la empresa no estamos autorizados a comprar autos prendados ni con planes de ahorro.',
                categoryId: 'sell'
            },
            {
                q: '¿Aceptan autos con deuda de Patente o Multas?',
                a: 'Sí, aceptamos autos que tengan deuda de patente o multas y nosotros nos hacemos cargo del trámite y del pago siempre que no estén en controlador o judicializadas. Tené en cuenta que el monto de la deuda se descontará de la oferta final por el auto.',
                categoryId: 'sell'
            },
            {
                q: '¿En qué consiste la evaluación?',
                a: `Una vez realizada la cotización inicial de tu auto podes continuar el proceso de evaluación de manera online vos mismo o ser contactado por un asesor para que te guíe en el proceso.

Esta evaluación es el diagnóstico que todo comprador le realizaría a un auto para conocer el estado del mismo y poder hacer una oferta de compra. Se revisan, entre otras cosas, el kilometraje, la carrocería, la tapicería, el motor, los neumáticos y la caja de velocidades.

Al concluir la evaluación remota, un asesor te presentará una oferta final de compra la cuál podés aceptar o declinar. En caso de aceptar, se comienzan los trámites gestionados por nosotros y se agenda una cita para la entrega, inspección y pago inmediato del auto.`,
                categoryId: 'sell'
            },
            {
                q: '¿Qué costo tiene la evaluación?',
                a: 'La evaluación es completamente gratuita y sin compromisos. Podés hacer todo el proceso sin moverte de tu casa hasta aceptar nuestra oferta por la compra de tu auto.',
                categoryId: 'sell'
            },
            {
                q: '¿Cuánto dura la evaluación?',
                a: 'Dependerá del tipo de evaluación que elijas hacer. Si preferís hacer vos mismo una autoevaluación completando un formulario en nuestra web, la misma llevará aproximadamente 5 a 10 minutos dependiendo de lo que tardes en responder cada una de las preguntas. Ahora, si preferís hacer una evaluación asistida por teléfono con uno de nuestros asesores, la misma tiene una duración aproximada de 10 minutos dependiendo del tiempo que tardes en encontrar el detalle de las cosas que debemos evaluar en tu auto.',
                categoryId: 'sell'
            },
            {
                q: '¿Puedo hacer una evaluación a distancia un fin de semana?',
                a: 'Todos nuestros asesores están disponibles de Lunes a Domingo para ayudarte a hacer tu evaluación. También podés elegir hacerla vos mismo desde la web completando un formulario.',
                categoryId: 'sell'
            },
            {
                q: '¿Qué documentos debo tener a la mano al momento de la evaluación?',
                a: 'Para la evaluación no es necesario tener ningún documento específico, solo contar con el número de patente y el kilometraje del auto.',
                categoryId: 'sell'
            },
            {
                q: '¿Qué pasa si me llaman para la evaluación y no pude atender?',
                a: 'No te preocupes, un asesor hará el intento de volver a comunicarse con vos. De todos modos, si querés contactarte con nosotros podés escribirnos a [contacto@olxautos.com.ar](mailto:contacto@olxautos.com.ar), llamarnos al [011 5544-4444](tel:+5401155444444) de Lunes a Viernes de 8 a 20hs o directamente mandarnos un mensaje al whatsapp [+54 9 11 3374 1444](https://wa.me/5491133741444).',
                categoryId: 'sell'
            },
            {
                q: '¿Dónde se realiza la evaluación?',
                a: 'En cualquier lugar donde te encuentres. La evaluación podés hacerla vos mismo respondiendo algunas preguntas online o podés elegir ser contactado para que te llame un asesor y evaluarlo en conjunto de manera remota. Solo tenés que iniciar el proceso de cotización y seleccionar la opción que sea más cómoda para vos.',
                categoryId: 'sell'
            },
            {
                q: '¿Qué pasa si no vivo en una de las ciudades en donde están presentes?',
                a: 'No pasa nada. Podés hacer el proceso de manera online desde cualquier parte del país. Recién una vez que aceptás nuestra oferta deberás acercarte presencialmente a algunas de nuestras oficinas comerciales para hacer la entrega del mismo.',
                categoryId: 'sell'
            },
            {
                q: 'Si no soy el dueño del auto, ¿puedo hacer la evaluación igual?',
                a: 'Una persona autorizada puede hacer la evaluación del auto remota sin problemas. De todos modos, en caso de aceptar nuestra oferta, el dueño debe asistir personalmente a nuestras oficinas comerciales para hacer la entrega, inspección y recibir el pago.',
                categoryId: 'sell'
            },
            {
                q: '¿Por qué la oferta final es distinta a la cotización que hice en la web?',
                a: `La cotización inicial corresponde a un rango de precios del modelo de tu auto en el mercado que varía de mayor a menor según la condición en la que se encuentre el mismo. A su vez, seguimos las tendencias de mercado por lo cual los actualizamos los precios a diario en base a 8 fuentes oficiales de información.

Para conocer el estado correcto del auto y poder hacer una oferta final es que realizamos una evaluación remota. Durante la misma un ejecutivo podrá explicar cómo llegamos al precio que te ofrecemos.`,
                categoryId: 'sell'
            },
            {
                q: '¿Por qué la oferta final es menor a lo que me ofrecerían en otros portales?',
                a: `En OLXmobbi usamos 8 fuentes de información a diario para conocer los precios de mercado. Luego, tras hacer la evaluación correcta de tu vehículo, te hacemos una oferta final según su estado.

También te asesoramos en todo el proceso y te ayudamos a gestionar los trámites para que no te tengas que preocupar de nada.`,
                categoryId: 'sell'
            },
            {
                q: '¿Si no acepto la oferta existe algún pago asociado?',
                a: 'No, si no estás de acuerdo con el precio no tenés pagar absolutamente nada. La evaluación es gratuita y sin ningún compromiso.',
                categoryId: 'sell'
            },
            {
                q: '¿El valor final es igual a la tasación fiscal del vehículo?',
                a: 'No, la tasación fiscal solo considera año, marca, modelo y versión del auto. La oferta final de OLXmobbi responde al resultado de la evaluación correcta del estado de tu auto, que luego es explicada por nuestro Asesor.',
                categoryId: 'sell'
            },
            {
                q: '¿Qué pasa si no estoy de acuerdo con la oferta final?',
                a: 'Si tras la explicación de nuestro asesor sobre cómo se llegó a la oferta final de tu auto no estás de acuerdo con el monto y decidís no venderlo, no hay problema. La evaluación es gratuita y sin compromiso alguno.',
                categoryId: 'sell'
            },
            {
                q: '¿Cuánto tiempo tengo para decidir si acepto la oferta final?',
                a: 'Desde el momento de la evaluación tenés 48hs para aceptar nuestra oferta.',
                categoryId: 'sell'
            },
            {
                q: '¿Qué otros trámites debo hacer para concretar la venta?',
                a: 'Si ya realizaste la evaluación y aceptaste nuestra oferta, nosotros te podemos ayudar con la documentación requerida a través de nuestro equipo de gestores. Podemos asesorarte o gestionarte varios de los trámites sin costo adicional por gestión. Eso sí, tené en cuenta que el pago de los trámites requeridos a entidades terceras se descontará de la oferta final por el auto.',
                categoryId: 'sell'
            },
            {
                q: '¿Cuál es la forma de pago?',
                a: 'Si aceptas la oferta, el pago se realizará a través de una transferencia bancaria. Por seguridad, no realizamos pagos en efectivo.',
                categoryId: 'sell'
            },
            {
                q: '¿Me pueden pagar en efectivo?',
                a: 'No, por seguridad todos los pagos se realizan a través de transferencia bancaria.',
                categoryId: 'sell'
            },
            {
                q: '¿Qué pasa si me arrepiento?',
                a: 'Si aceptaste nuestra oferta y ya comenzamos con la gestión documental, podés decidir no vendernos el auto y detener el proceso. Sin embargo, tené en cuenta que no se reembolsará el monto de los informes ya gestionados.',
                categoryId: 'sell'
            },
            {
                q: '¿Cómo es el trámite de la venta?',
                a: 'Una vez que recibimos el peritaje firmado por el cliente se le envía un mail de bienvenida indicando detalladamente la documentación requerida y se procesa internamente el pedido de informes con nuestro equipo de gestores el cual demora entre 48hs y 72hs. También tenemos casos donde los clientes desean realizar toda la operación por su cuenta.',
                categoryId: 'sell'
            },
            {
                q: '¿Cómo es el trámite del Formulario 08?',
                a: 'Al momento de la venta, uno de los pasos administrativos es entregarte el Formulario de Compra de Bienes Usados, dicho formulario puede ser presentado ante la AFIP. También nos podés emitir una denuncia impositiva de venta.',
                categoryId: 'sell'
            },
            {
                q: '¿Cómo se hace la denuncia de venta?',
                a: 'Para realizar la denuncia de venta el cliente deberá efectuar la entrega de la unidad. Este trámite solo puede realizarlo el titular del vehículo en el Registro de radicación y puede hacerlo presencial y online con el Comprobante de Bienes Usados que nosotros le entregamos una vez terminado el proceso de compra. Para realizar este trámite el costo lo afronta el titular y tiene 30 días para realizarlo.',
                categoryId: 'sell'
            },
            {
                q: '¿Cuáles son los gastos de los informes?',
                a: 'Dentro de los gastos cobrados al cliente se comprende: el Informe de dominio Histórico, Informe de multas, Liquidación de ARBA o AGIP, Certificación de firmas mediante escribano y Formulario 08.',
                categoryId: 'sell'
            },
            {
                q: '¿Cómo es el proceso del pago de multas?',
                a: 'Una vez comprado el auto por la administración de OLXmobbi, y en caso de que el cliente haya dado consentimiento para que se descuenten las infracciones, se solicita el pago vía Web al RRSS en un plazo de 10 días hábiles.',
                categoryId: 'sell'
            },
            {
                q: '¿Ustedes hacen la transferencia del titular?',
                a: `Por lo general, los autos comprados no se transfieren a nombre de la empresa, quedan con F08 abierto durante 90 días hábiles administrativos y luego de ese plazo se transfiere a nombre de OLXmobbi.

En el caso que el cliente lo solicite al momento de entregar la unidad, se puede realizar cobrando los aranceles por los gastos del trámite de transferencia ($4100 + sellado provincial) y se asienta el bien a nombre de la empresa. El trámite demora aproximadamente 10 días hábiles y el cliente será notificado por mail de la DNRPA, informando el cambio de titularidad.`,
                categoryId: 'sell'
            },
            {
                q: '¿Ustedes emiten factura al momento de comprar?',
                a: 'Al momento de la compra del auto, como comerciantes habitualistas, estamos obligados a entregar al cliente el Comprobante de Bienes Usados, el cual se declara en AFIP por la compra de la unidad. Con este documento, el cliente tiene justificado el ingreso en el banco y en la AFIP. En caso que la unidad esté a nombre de una empresa, se debe facturar por el bien que vende.',
                categoryId: 'sell'
            },
            {
                q: '¿Cobran por gastos administrativos?',
                a: 'El cliente puede delegarnos la totalidad de la gestión administrativa de los siguientes documentos: Informe de dominio Histórico, Informe de Multas, Liquidación de ARBA o AGIP, Certificación de firmas mediante escribano, Formulario 08 y Gastos Administrativos. Esto tiene un costo de $5.974 en caso de ser soltero y $8.474 para casados.',
                categoryId: 'sell'
            },
            {
                q: '¿Ustedes hacen el cambio de registro a la Provincia de Buenos Aires?',
                a: 'Una vez que recibimos el peritaje firmado por el cliente se le envía un mail de bienvenida indicando detalladamente la documentación requerida y se procesa internamente el pedido de informes con nuestro equipo de gestores el cual demora entre 48hs y 72hs, entre ellos el cambio de registro. El proceso de la compra a veces puede estar demorado, cuando el cliente desea hacer parte de la operación de la venta ya que puede tener trámites pendientes como: una prenda por levantar, inhibición, multas en controlador, turno para hacer la VPA, VTV y G01.',
                categoryId: 'sell'
            },
            {
                q: '¿Cuánto tiempo tarda la devolución de las patentes y multas descontadas al momento de la compra?',
                a: 'La deuda descontada al cliente dependerá del pago efectuado en cada entidad, sea multas (RRSS) o patentes (se realiza el pago por Mercado Pago). Los reintegros se realizan los días lunes de cada semana, por transferencia o por mercado pago, de acuerdo a lo acordado con el cliente.',
                categoryId: 'sell'
            },
            {
                q: '¿Existe algún comprobante de pago que confirme que nosotros ya pagamos por las multas y patentes descontadas al momento de la compra?',
                a: 'Esto se puede chequear ingresando al sitio web [https://www2.jus.gov.ar/dnrpa-site/#!/solicitante](https://www2.jus.gov.ar/dnrpa-site/#!/solicitante) y solicitando un informe de infracciones para chequear el pago.',
                categoryId: 'sell'
            },
            {
                q: '¿Cuáles son los 10 documentos necesarios para vender un auto en Argentina?',
                a: `1) Título original del auto

2) Cédula verde y cédula azul

3) DNI vigente y CUIT/CUIL del titular

4) Informe de dominio histórico

5) Informe de infracción

6) Libre deuda de patente

7) Verifiación policial

8) Grabado de autopartes

9) Verifitación Técnica Vehicular (VTV)

10) Tarjeta de GNC (en caso de tener)

Te ayudamos a gestionar algunos de estos documentos sin costo adicional por gestión. Conocé el detalle de cada uno de ellos en el siguiente [link.](/documentos-necesario-para-vender-tu-auto)`,
                categoryId: 'sell'
            },
            {
                q: '¿Qué es OLXmobbi?',
                a: 'Somos un sitio web que compra y vende autos usados. Lo hacemos de manera directa y transparente. Nuestro objetivo es transformar la manera en la que una persona vende y compra su auto usado, simplificando los procesos, asesorando con los trámites y brindando seguridad y comodidad en cada transacción.',
                categoryId: 'buy'
            },
            {
                q: '¿En qué ciudades están presentes?',
                a: `Actualmente tenemos oficinas comerciales en:

Buenos Aires: Av. Warnes 2707, Capital Federal

Buenos Aires: Shopping Norcenter, Esteban Echeverría 3750

Córdoba: Av. Colón 4551

Salta: Complejo office park, Av. Río de la Plata s/n, San Lorenzo

Mendoza: Lateral Sur del Acceso Este, M5521 Guaymallén`,
                categoryId: 'buy'
            },
            {
                q: 'Quiero comprar un auto con ustedes, ¿Dónde puedo verlos?',
                a: `Tenemos 3 maneras en las que podés encontrar tu auto ideal:

1. Comprá directamente en [nuestra web](/comprar-un-auto).
2. Llamanos o escribinos al [+54 9 11 2640-5695](tel:+5491126405695), contanos qué auto buscás y tu presupuesto aproximado y te daremos alternativas personalizadas.
3. Visitanos en nuestras oficinas comerciales de Buenos Aires en el Shopping Norcenter - Esteban Echeverría 3750 y en la Provincia de Córdoba en Avenida Colón 4551.`,
                categoryId: 'buy'
            },
            {
                q: '¿Cómo puedo saber si el auto que me interesa comprar está en buen estado?',
                a: 'Nuestros autos pasan por una evaluación minuciosa de 240 puntos, consideramos: la parte estética, mecánica y documental.',
                categoryId: 'buy'
            },
            {
                q: '¿Puedo también venderles mi auto?',
                a: `Sí, podés cotizar y evaluar tu auto de forma online vos mismo o con ayuda de nuestros asesores para obtener una oferta por el mismo. En caso de aceptarla, podés acercarte a cualquiera de nuestras oficinas comerciales del país para entregarlo. Allí le haremos una inspección final y te pagaremos al instante mediante transferencia bancaria.

Cotizalo ahora [acá.](/vende-tu-auto)`,
                categoryId: 'buy'
            },
            {
                q: '¿Sus autos cuentan con garantía?',
                a: 'Todos nuestros autos cuentan con una garantía mecánica de 12 meses la cual incluye motor, caja y diferencial del vehículo.',
                categoryId: 'buy'
            },
            {
                q: '¿Puedo hacer prueba de manejo?',
                a: 'Claro que sí, primero podés hacer un test drive en nuestras oficinas y luego, para que tomes la mejor decisión, podés probarlo durante 7 días desde el momento en que retirás el auto. Si no estás satisfecho, te devolvemos la plata.',
                categoryId: 'buy'
            }
        ],

        goBackBtnTitle: 'VOLVER'
    },
    olxtr: {
        title: 'Sıkça sorulan sorular',
        tabs: [{ title: 'Araba satarken', categoryId: 'sell' }, { title: 'Araba alırken', categoryId: 'buy' }],
        notFoundTitle: 'Sonuç bulunamadı',
        notFoundSubtitle: '"searchValue" için sonuç bulunamadı. Farklı bir arama yapabilirsin veya sorunu başka bir şekilde yazmayı deneyebilirsin.',
        content: [
            {
                a: '- İlk önce araban için ön fiyat teklifi alman gerekiyor: letgo uygulamasına gir, arabanı yüklemeye başla ve hemen ön fiyat teklifini al. Aynı zamanda otoplus web sitesi üzerinden arabana ait bilgileri girerek ön fiyat teklifi alabilirsin.\n- Ön fiyat teklifimizle ilgileniyorsan, tercih ettiğin merkezimizde istediğin gün ve saatte ücretsiz ekspertiz randevunu online olarak oluşturabilirsin. Sonrasında seni arayarak yanında getirmen gereken belgeler hakkında bilgi vereceğiz. Merak ettiğin tüm soruları yanıtlamaktan memnuniyet duyarız.\n- Merkezimizde uzman eksperlerimiz tarafından gerçekleştirilen ve ortalama 30 dakika süren ekspertiz sonrasında son fiyat teklifimizi sunuyoruz. (Ön fiyat teklifimiz ile son fiyat teklifimiz arasında ekspertiz sonucu nedeniyle fiyat farkı oluşabilir.)\n- Son fiyat teklifimizi kabul edersen hemen satış işlemlerine geçiyoruz ve noter işlemleriyle eş zamanlı olarak ödemeni güvenli bir şekilde banka hesabına yatırıyoruz.',
                q: 'Arabamı satmak istiyorum. Ne yapmam gerekiyor?',
                categoryId: 'sell'
            },
            {
                a: 'Merkezlerimiz sekmesinden tüm araba alım merkezlerimizin çalışma saatlerine* ulaşabilirsin.\n\n*T.C. İçişleri Bakanlığı tarafından açıklanan genelgeler doğrultusunda çalışma günleri ve saatleri değişiklik gösterebilir.',
                q: 'Çalışma saatleriniz nedir?',
                categoryId: 'sell'
            },
            {
                a: 'Evet, iyi bir haberimiz var; araba bayilerinden de araba alıyoruz.',
                q: 'Araba bayisiyim. Size araba satabilir miyim?',
                categoryId: 'sell'
            },
            {
                a: 'Misafirlerimizin ve çalışanlarımızın sağlığı bizim için çok önemli. Bu sebeple ekspertiz merkezimizde tüm Covid-19 önlemlerini alıyoruz. Ekspertiz merkezimiz düzenli aralıklarla dezenfekte ediliyor. Merkezimizdeki tüm otoplus profesyonellerimiz ekspertiz süresince eldiven ve maske kullanıyor; merkezimizdeki tüm çalışanlarımız tüm gün boyunca tüm gereken önemleri alıyorlar. Merkezimize maskesiz giriş yapılamayacağı için maskeyle gelmeni rica ediyoruz.',
                q: 'Koronavirüse karşı ne önlemler alınıyor?',
                categoryId: 'sell'
            },
            {
                a: "Tüm bu kriterleri kapsayan arabaları değerlendirmeye alıyoruz:\n\n \n\n- 160.000 km'yi aşmamış, 2005-2022 model\n- 6 aydan büyük ve 6.000 km’yi aşmış\n- Kilometre bilgisi değiştirilmemiş\n- Ruhsatta araç cinsi bölümünde \"otomobil\" yazan (taksi, ticari veya kamyonet olarak geçmeyen)\n- Taksi geçmişi olmayan\n- Airbag işlemli veya açılmış olmayan\n- Tavanı boyanmamış veya değişmemiş olan\n- Ağır hasar veya pert kaydı olmayan\n- Sel/su hasarı olmayan\n- Modifiye edilmemiş\n- Çalıntı kaydı veya şaside işlemi olmayan\n- Vergi/ceza borcu olmayan\n- Üzerinde haciz/ipotek olmayan\n- Tramer kaydı araba değerinin %10’undan az olan\n- En fazla 1.25 milyon TL değerinde",
                q: 'Hangi arabalar fiyat teklifi alabilir?',
                categoryId: 'sell'
            },
            {
                a: 'Ön fiyat teklifi, paylaştığın araba bilgilerine ve aynı özelliklere sahip arabaların piyasadaki ikinci el satış fiyatlarına göre belirlenir. Fiyatlandırma algoritmamız sayesinde sana şeffaf ve değerinde fiyat teklifi vermeye çalıştığımıza emin olabilirsin.',
                q: 'Arabama verilen ön fiyat teklifi nasıl belirleniyor?',
                categoryId: 'sell'
            },
            {
                a: 'Ön fiyat teklifimizle ilgileniyorsan, tercih ettiğin merkezimizde istediğin gün ve saatte ücretsiz ekspertiz randevunu online olarak oluşturabilirsin. Randevuna gelirken kimliğini, arabanın ruhsatını ve yedek anahtarlarını yanında getirmeyi unutma ve lütfen arabanın yıkanmış ve temizlenmiş olduğuna emin ol. Arabanın sahibi değilsen, işlem yapılabilmesi için araba sahibinden adına verilmiş bir vekaletname olması gerekir.',
                q: 'Verilen teklifi beğendim. Sonrasında ne yapmam lazım?',
                categoryId: 'sell'
            },
            {
                a: 'İstediğin kadar ön fiyat teklifi alabilirsin.',
                q: 'Kaç kez ön fiyat teklifi alabilirim?',
                categoryId: 'sell'
            },
            {
                a: 'otoplus ekspertiz merkezinde araban güvenilir ve profesyonel eksperlerimiz tarafından incelenir. Lütfen randevuna gelirken kimliğini, arabanın ruhsatını ve yedek anahtarlarını yanında getirmeyi unutma ve arabanın yıkanmış ve temizlenmiş olduğuna emin ol. Yaklaşık 30 dakika sürecek ekspertiz işlemi sonrasında sana son fiyat teklifimizi sunarız.',
                q: 'Ekspertiz süreci nasıl işliyor?',
                categoryId: 'sell'
            },
            {
                a: 'letgo uygulaması veya otoplus internet sayfası üzerinden ön fiyat teklifi aldıktan sonra tercih ettiğin merkezimizde istediğin gün ve saatte ücretsiz ekspertiz randevunu online olarak oluşturabilirsin. Sonrasında seni arayarak yanında getirmen gereken belgeler hakkında bilgi vereceğiz. Merak ettiğin tüm soruları yanıtlamaktan memnuniyet duyarız.',
                q: 'Nasıl ekspertiz randevusu alabilirim?',
                categoryId: 'sell'
            },
            {
                a: 'Randevuna gelirken kimliğin, arabanın ruhsatın ve yedek anahtarların yanında olmalı ve ayrıca araban yıkanmış ve temizlenmiş olmalıdır. Arabanın sahibi değilsen, işlem yapılabilmesi için araba sahibinden adına verilmiş bir vekaletname olması gerekir.',
                q: 'Ekspertiz randevusuna gelirken yanımda hangi belgeler olmalı?',
                categoryId: 'sell'
            },
            {
                a: '[Merkezlerimiz](https://www.otoplus.com/alim-satim-merkezleri) bölümünden tüm lokasyonlarımızın adresine ulaşabilirsin. Daha fazla yerde hizmet vermek için çalışmalarımız tüm hızıyla devam ediyor',
                q: 'Ekspertiz merkezleriniz nerede?',
                categoryId: 'sell'
            },
            {
                a: '- Arabanın genel bilgilerinin tespiti\n- İç aksam kontrolleri\n- Boyanmış ve değişen parçaların kontrolü\n- Mekanik kontroller\n- Diyagnoz ve ekspertiz raporunun sisteme kaydedilmesi',
                q: 'Ekspertiz sırasında hangi kontroller yapılıyor?',
                categoryId: 'sell'
            },
            {
                a: 'Ekspertiz hizmetimiz ücretsizdir.',
                q: 'Ekspertiz ücretli mi?',
                categoryId: 'sell'
            },
            {
                a: 'Hiç sorun değil, yeni bir randevu oluşturmak için 0850 955 50 50 nolu telefondan bize ulaşabilirsin.',
                q: 'Ekspertiz randevuma geç kalırsam iptal mi olur?',
                categoryId: 'sell'
            },
            {
                a: 'Araban otoplus ekspertiz merkezinde güvenilir ve profesyonel eksperlerimiz tarafından incelenirken sen de tüm süreci izleyebilirsin.',
                q: 'Yapılan ekspertize nasıl güvenebilirim?',
                categoryId: 'sell'
            },
            {
                a: 'Son fiyat teklifi hesaplanırken arabanın durumu, kullanımı, hasar kaydı ve mevcut pazar talep ve arz durumu gibi birçok unsur göz önüne alınır.',
                q: 'Ekspertiz sonrasında verdiğiniz son teklif nasıl belirleniyor?',
                categoryId: 'sell'
            },
            {
                a: 'Ekspertiz merkezimizdeki yoğunluğa göre beklemen gerekebilir bu sebeple işlemlerin daha hızlı yürütülmesi için randevu almanı tavsiye ederiz.',
                q: 'Ekspertiz randevusu almadan gelebilir miyim?',
                categoryId: 'sell'
            },
            {
                a: 'Son fiyat teklifimizi kabul edersen hemen arabanın satış işlemine başlıyoruz. Noter işlemlerinin hızlıca tamamlanması için kimliğine, arabanın ruhsatına, yedek anahtarlarına ve banka hesabı bilgilerine ihtiyacımız olacak. Arabanın sahibi değilsen, işlem yapılabilmesi için araba sahibinden adına verilmiş bir vekaletname olması gerekiyor. Noter işlemleri ile eş zamanlı olarak paranı "güvenli ödeme sistemiyle" banka hesabına anında yatırıyoruz.',
                q: 'Ödeme süreci nasıl işliyor?',
                categoryId: 'sell'
            },
            {
                a: 'Ödemeyi ruhsat sahibinin banka hesabına transfer yapıyoruz.',
                q: 'Ödeme nasıl yapılıyor?',
                categoryId: 'sell'
            },
            {
                a: 'otoplus’a arabanı satarken seni yoracak hiçbir prosedürle uğraşmadan ödemeni gönül rahatlığıyla alabiliyorsun. Arabanı satmaya karar verdiğinde noter işlemleri ile eş zamanlı olarak paranı "güvenli ödeme sistemiyle" banka hesabına yatırıyoruz.',
                q: 'Ödemeyi nasıl ve ne zaman alabilirim?',
                categoryId: 'sell'
            },
            {
                a: 'Hayır, araba satışında bir komisyon bedeli almıyoruz.',
                q: 'Komisyon bedeli alınıyor mu?',
                categoryId: 'sell'
            },
            {
                a: 'Noter satış bedelini arabayı satın alan taraf olarak biz karşılıyoruz.',
                q: 'Noter ücretini kim karşılıyor?',
                categoryId: 'sell'
            },
            {
                a: 'Ekspertiz sonrasında hemen noter işlemlerine geçiyoruz. En kısa zamanda işlemleri tamamlıyoruz.',
                q: 'Satış işlemleri ne kadar sürüyor?',
                categoryId: 'sell'
            },
            {
                a: 'Satış işlemlerini iş ortaklarımızla yürütüyoruz.',
                q: 'Satış işlemlerini kim yürütüyor?',
                categoryId: 'sell'
            },
            {
                a: 'İkinci el araba satın almak oldukça uzun ve zahmetli bir süreç. otoplus olarak amacımız kolay, hızlı ve güvenli bir şekilde araba satın alabilmeni sağlamak. Kapsamlı sigorta, arabaların güvenilir ve şeffaf bir şekilde değerlendirilmesi, doğru fiyatlama, uçtan uca satış sürecinin kolayca tamamlanabilmesi gibi birçok avantajımızla zengin araba portföyümüzden hayalindeki arabaya sahip olabilirsin. Merkezlerimizdeki uzman satış ekibimiz ve çağrı merkezlerimizle sana en iyi hizmeti verebilmek için çalışıyoruz.',
                q: 'otoplus nedir ve ne avantajlar sunuyor?',
                categoryId: 'buy'
            },
            {
                a: "otoplus olarak amacımız kolay ve güvenli bir şekilde araba satın alabilmeni sağlamak.\n\notoplus'ın zengin portföyündeki arabaları:\n\n- letgo uygulaması ve otoplus web sitesi üzerinden inceleyebilirsin\n- Merkezimize gelerek yerinde görebilirsin\n\nArabalarımızla ilgileniyorsan hemen 0850 955 90 90'i ara, sana uygun gün ve saate randevunu oluştur. Merkezimizde arabaları yerinde görüp inceleyebilir ve satış yetkililerimizden ilgilendiğin araba hakkında detaylı bilgi alabilirsin. Eğer satın almaya karar verirsen hemen satış işlemlerine geçiyoruz ve aynı gün içinde tüm işlemleri tamamlıyoruz.",
                q: 'Araba satın almak istiyorum. Ne yapmam gerekiyor?',
                categoryId: 'buy'
            },
            {
                a: 'Merkezlerimiz sekmesinden tüm araba satım merkezlerimizin çalışma saatlerine* ulaşabilirsin.\n\n*T.C. İçişleri Bakanlığı tarafından açıklanan genelgeler doğrultusunda çalışma günleri ve saatleri değişiklik gösterebilir.',
                q: 'Çalışma saatleriniz nedir?',
                categoryId: 'buy'
            },
            {
                a: 'Evet, tabii ki de.',
                q: 'Araba bayisiyim. Sizden araba satın alabilir miyim?',
                categoryId: 'buy'
            },
            {
                a: 'Tabii mümkün. Satın aldığın arabanın ekspertizi yapıldıktan sonra sana fiyat teklifimizi sunuyoruz. Teklifimizi kabul edersen satış işlemleri hızlıca tamamlıyoruz.',
                q: 'Sizden aldığım arabayı tekrar size geri satabilir miyim?',
                categoryId: 'buy'
            },
            {
                a: "Tüm arabalarımız letgo güvencesi altındadır:\n\n- Bağımsız ekspertiz*\n- 12 ay/20.000 km garanti (Sadece 8 yaşın veya 160.000 km'nin altındaki arabalar için geçerlidir.) \n- 20 gün içinde iade garantisi* (Belirli şartlara tabiidir) \n- Garanti süresi boyunca 7/24 yol yardımı\n\nEkspertiz raporunu detaylı bir şekilde inceleyebilir, arabalarımızı en ince ayrıntısına kadar görebilmek için randevu alabilirsin. \n\n*Anlaşmalı çözüm ortağımız tarafından sağlanmaktadır.\n\n**20 gün içerisinde iade hakkı, arabanın satın alındığı tarihten itibaren en fazla 500 km kullanılmış olması ve arabanın ilk ekspertiz raporu ile aynı koşulda bulunması (herhangi bir hasar bulunmaması vs.) gibi şartlara tabiidir. Değişim ve iade şartlarıyla ilgili detaylı bilgiye sayfanın devamında ulaşabilirsin.",
                q: 'Sattığınız arabalar orijinal mi, nasıl güvenebilirim?',
                categoryId: 'buy'
            },
            {
                a: "Hayır, otoplus'ta ekspertiz ve noter işlemleri ücretsizdir.",
                q: 'Hizmet bedeli alınıyor mu?',
                categoryId: 'buy'
            },
            {
                a: 'Elbette, seni ağırlamaktan mutluluk duyarız. Dileğin otoplus merkezinden randevu alarak arabalarımızı yerinde inceleyebilirsin, satış yetkililerimizden ilgilendiğin araba hakkında detaylı bilgi alabilirsin.',
                q: 'İlgilendiğim arabayı gelip görebilir miyim?',
                categoryId: 'buy'
            },
            {
                a: "Tabii ki ama yaşanabilecek yoğunluk sebebiyle randevu almanı tavsiye ediyoruz. 0850 955 90 90'ı arayarak randevunu oluşturabilirsin.",
                q: 'Randevu almadan gelebilir miyim?',
                categoryId: 'buy'
            },
            {
                a: 'Satıştaki arabalarımızın ilanlarına otoplus web sitesi ya da letgo uygulaması üzerinden ulaşabilirsin. Daha fazla bilgi almak için merkezlerimizi ziyaret ederek arabalarımızı yerinde inceleyebilir, satış yetkililerimizden detaylı bilgi alabilirsin.',
                q: 'Arabalar hakkında nasıl detaylı bilgi alabilirim?',
                categoryId: 'buy'
            },
            {
                a: "otoplus'ta önceliğimiz senin memnuniyetin, o yüzden tüm satın alma sürecini kolay ve zahmetsiz bir hale getirdik.\n\notoplus'ın zengin portföyündeki arabaları:\n\n- letgo uygulaması ve otoplus web sitesi üzerinden inceleyebilirsin\n- Merkezimize gelerek yerinde görebilirsin\n\nTüm arabalarımız letgo güvencesi altındadır:\n\n- Bağımsız ekspertiz*\n- 12 ay/20.000 km garanti (8 yaşın ve 160.000 km'nin altındaki arabalar için geçerlidir.)\n- 20 gün içinde iade garantisi** (Belirli şartlara tabiidir)\n- Garanti süresi boyunca 7/24 yol yardımı\n\nArabalarımızla ilgileniyorsan hemen 0850 955 90 90'ı ara, sana uygun gün ve saate randevunu oluştur. Merkezimizde arabaları yerinde görüp inceleyebilir ve satış yetkililerimizden ilgilendiğin araba hakkında detaylı bilgi alabilirsin. Eğer satın almaya karar verirsen hemen satış işlemlerine geçiyoruz ve aynı gün içinde tüm işlemleri tamamlıyoruz.\n\n*Anlaşmalı çözüm ortağımız tarafından sağlanmaktadır.\n\n**20 gün içerisinde iade hakkı, arabanın satın alındığı tarihten itibaren en fazla 500 km kullanılmış olması ve arabanın ilk ekspertiz raporu ile aynı koşulda bulunması (herhangi bir hasar bulunmaması vs.) gibi şartlara tabiidir.Değişim ve iade şartlarıyla ilgili detaylı bilgiye sayfanın devamında ulaşabilirsin.\n",
                q: 'Araba satın alma süreci nasıl işliyor?',
                categoryId: 'buy'
            },
            {
                a: 'Hayalindeki arabaya sahip olabilmen için sana özel kredi seçenekleri ve ödeme yöntemleri sunuyoruz. Merkezimizdeki krediden sorumlu danışmanlarımız, anlaşmalı kurumlarımızla birlikte ihtiyaçlarına uygun kredi seçeneklerini bulmak için her zaman yanında olacak. Kredi başvurunla ilgili onay ve evrak süreçlerini merkezimizde hızlıca bitirebilirsin.\nAyrıca, ödemeyi de merkezimize geldiğinde gerçekleştirebilirsin. "Güvenli ödeme sistemi" üzerinden ister nakit ister kredi kartı ile ödeme yapabilirsin.',
                q: 'Ödemeyi nasıl yapabilirim? Finansman seçenekleri var mı?',
                categoryId: 'buy'
            },
            {
                a: 'İlgilendiğin araba hakkında düşünmek için zamana ihtiyacın varsa kapora yatırarak arabayı 3 gün boyunca rezerve edebilirsin. Kaporayı EFT/havale ile iletebilirsin.',
                q: 'Ödeme yapmak için ne kadar sürem var? Kapora alıyor musunuz?',
                categoryId: 'buy'
            },
            {
                a: 'Araban satın alma kriterlerimize uyuyorsa takas yapmaktan memnuniyet duyarız. Önce uygulama ya da web sayfamız üzerinden takas etmek istediğin araba için ön fiyat teklifi alman gerekiyor. Sonrasında ekspertiz merkezimizde arabana yapılacak profesyonel ekspertiz sonrasında sana son fiyat teklifimizi yapıyoruz.',
                q: 'Takas yapıyor musunuz?',
                categoryId: 'buy'
            },
            {
                a: 'Arabayı almaya karar verdiğin andan itibaren aynı gün içinde en hızlı şekilde işlemleri tamamlıyoruz.',
                q: 'Satış işlemleri ne kadar sürüyor?',
                categoryId: 'buy'
            },
            {
                a: 'Noter işlemleri için bir ücret almıyoruz.',
                q: 'Noter hizmet bedeli ne kadar?',
                categoryId: 'buy'
            },
            {
                a: 'Plaka değişim bedelini kendin ödeyerek plaka değişikliğini noter işlemleri sırasında yapabilirsin.',
                q: 'Plaka değişikliği yapabilir miyim?',
                categoryId: 'buy'
            },
            {
                a: 'Satın alma işlemi bittikten sonra hemen sana teslim ediyoruz.',
                q: 'Araba ruhsatı ve noter satış belgesini nasıl alabilirim?',
                categoryId: 'buy'
            },
            {
                a: 'Senin için anlaşmalı şirketler arasından en iyi trafik sigortası ve kasko alternatiflerini dakikalar içinde buluyoruz. Dilersen sana sunduğumuz trafik sigorta ve kasko hizmetini kullanabilirsin. Böylece kapıdan çıktığın anda araban sigortanmış olur. \n\nDilersen çözüm ortağımız sigortaladim.com platformu üzerinden birçok sigorta şirketinden trafik ve kasko sigortanı yaptırabilirsin.',
                q: 'Trafik ve Kasko sigortası hizmeti sunuyor musunuz?',
                categoryId: 'buy'
            },
            {
                a: 'Hayır, araba ilanında gördüğün fiyat geçerlidir.',
                q: 'Satın alma sürecinde arabanın fiyatı değişir mi?',
                categoryId: 'buy'
            },
            {
                a: 'Tüm arabalarımız bağımsız ekspertiz raporuna sahiptir. Arabalarımızın profesyonel ve detaylı ekspertizini otoplus merkezimizde uzmanlarımız gerçekleştiriyor. Bu konuda şeffaflığa çok önem veriyoruz; istersen ekspertiz raporunu en ince ayrıntısına kadar inceleyebilirsin.',
                q: 'Arabaya ekspertiz yapılıyor mu? Nasıl yapılıyor?',
                categoryId: 'buy'
            },
            {
                a: '- Arabanın genel bilgilerinin tespiti \n- İç aksam kontrolleri\n- Boyanmış ve değişen parçaların kontrolü\n- Genel arıza tarama\n- Mekanik kontroller\n- Diyagnoz ve ekspertiz raporunun sisteme kaydedilmesi',
                q: 'Ekspertiz raporunda hangi bilgiler ve kontroller bulunuyor?',
                categoryId: 'buy'
            },
            {
                a: 'Bu mümkün değil ama profesyonel uzmanlar tarafından yapılan bağımsız ekspertiz raporuna rahatlıkla güvenebilirsin ve üstelik ücretsizdir. Bu konuda şeffaflığa çok önem veriyoruz. Sen de ekspertiz raporunu en ince ayrıntısına kadar inceleyebilirsin.',
                q: 'Ekspertizi başka yerde yaptırabilir miyim?',
                categoryId: 'buy'
            },
            {
                a: 'Fiyatlar piyasa koşulları ve araba maliyetleri dikkate alınarak belirlenir ve arabalarımız olabilecek en düşük marjlarla satışa sunulur.',
                q: 'Arabaların değerini nasıl belirliyorsunuz?',
                categoryId: 'buy'
            },
            {
                a: 'Tüm arabalarımızın tramer hasar kayıt bilgilerini Trafik Sigortaları Bilgi Merkezi (SBM) tarafından alınan bilgiye göre müşterilerimizle paylaşıyoruz. Bu bilgilere ilan detaylarından ve merkezde araba tanıtım etiketlerinden ulaşabilirsin.\n\n\nTramer sorgulamasında oluşacak hata veya satış sonrası eklenecek hasar kayıtlarından otoplus sorumlu değildir.\n',
                q: 'Tramer (hasar) kayıtlarını paylaşıyor musunuz?',
                categoryId: 'buy'
            },
            {
                a: "8 yaşın veya 160.000 km'nin altındaki tüm arabalarımız* letgo güvencesi altındadır. Hiçbir ek ücret ödemeden 12 ay/20.000 km’ye kadar kapsamlı garanti ve 7/24 yol yardım hizmetine sahipsin.\n \n\n*Eğer satın alacağın arabanın Orijinal Ürün Garantisi hala devam ediyorsa, araba garanti altında olduğu için ek olarak 12 ay/20.000 km garantisi verilmez.",
                q: 'Arabaların garantisi var mı?',
                categoryId: 'buy'
            },
            {
                a: "Garanti arabanın satıldığı an başlar ve 12 ay veya 20.000 km'den hangisi önce dolarsa sona erer. Garantinin başlangıç ve bitiş tarihlerine garanti sertifikasından ulaşabilirsin.",
                q: 'Garanti süresi ne zaman başlar ve ne zaman sona erer?',
                categoryId: 'buy'
            },
            {
                a: "Garanti, arabanın mekanik ve elektrik arızaları ile ilgili parça ve işçilik ücretlerini “Garanti Özel Koşulları”nda belirtilen kapsam, esaslar ve tanımlanan “Azami Sorumluluk” dahilinde kapsar.\n \nGaranti hakkında daha detaylı bilgiye arabanı satın alırken Garanti Belgesi ile birlikte verilen otoplus Garanti Kitapçığı'ndan ulaşabilirsin.",
                q: 'Hangi arızalar garanti kapsamındadır?',
                categoryId: 'buy'
            },
            {
                a: "Garantisi kapsamı içerisinde tanımlanmış parçalar dışında kalan parçalar ile normal aşınma, normal yıpranma, normal eskime ya da hatalı kullanma veya ihmal sonucu olarak arızalanan parçaları kapsam dahilinde olsa dahi kapsamaz. Fonksiyon kaybı olmaksızın oluşabilecek ses vb. şikayetleri ve yaşa/kilometreye bağlı oluşabilecek doğal performans azalmaları da garanti kapsamına girmez.\n \nGarantisi hakkında daha detaylı bilgiye arabanı satın alırken Garanti Belgesi ile birlikte verilen otoplus Garanti Kitapçığı'ndan ulaşabilirsin.",
                q: 'Neler garanti kapsamında değildir?',
                categoryId: 'buy'
            },
            {
                a: 'Noter satış işlemlerinden sonra arabanı merkezimizden teslim alabilirsin.',
                q: 'Arabayı ne zaman ve nasıl teslim alabilirim?',
                categoryId: 'buy'
            },
            {
                a: 'Tabii ki de. Seçili şehirlerde otoplus eve teslimat kamyonumuzla güvenli şartlarda arabanı taşıyoruz ve ücretsiz bir şekilde adresine teslim ediyoruz.',
                q: 'Araba adresime gönderilir mi?',
                categoryId: 'buy'
            },
            {
                a: "Tabii ki, otoplus'ta önceliğimiz senin memnuniyetin. Eğer arabayı aldıktan sonra fikrin değişirse 20 gün içinde iade edebilir, ödemenin tamamını geri alabilirsin.",
                q: 'Satın aldığım arabayı iade edebilir miyim?',
                categoryId: 'buy'
            },
            {
                a: "otoplus'tan satın aldığın arabayı aşağıdaki şartlar kapsamında iade edebilirsin:\n \n- Noter satışının tamamlanmasından itibaren en fazla 20 gün geçmiş olması\n- Arabanın satın alma sırasındaki ekspertiz raporu ile aynı koşullarda olması (kaza yok, hasar yok vb.)\n- Arabaya bağlı herhangi bir aksesuarda eksiklik olmaması\n- Arabanın noterde geri alınması için yasal bir engel olmaması (kredi, vergi vb.)\n- Arabanın satın alındığından itibaren en fazla 500 km kullanılmış olması\n\nletgo, garanti kapsamında herhangi bir zamanda değişiklik yapma hakkını saklı tutar. İade imkanı yalnızca yılda bir kez geçerlidir. İade hakkı yalnızca son tüketiciler için geçerli olup galeri ya da ticari alıcılar için geçerli değildir.",
                q: 'İade şartları nedir?',
                categoryId: 'buy'
            },
            {
                a: 'Satın aldığın arabadan memnun kalmazsan hiç merak etme, 20 gün içinde otoplus portföyündeki başka bir arabayla değiştirebilirsin.*\n \n*Satın alınan araba ile yeni almak istenilen araba arasında fiyat farkı varsa bu fark alıcı tarafından karşılanır. Detaylı bilgi için "Değişim ve İade" ile "Satış ve Ödeme İşlemleri" bölümünlerine bakabilirsin.',
                q: 'Satın aldığım arabayı değiştirebilir miyim?',
                categoryId: 'buy'
            },
            {
                a: "otoplus'tan satın aldığın arabayı aşağıdaki şartlar kapsamında değiştirebilirsin:\n\n- Noter satışının tamamlanmasından itibaren en fazla 20 gün geçmiş olması\n- Arabanın satın alma sırasındaki ekspertiz raporu ile aynı koşullarda olması (kaza yok, hasar yok vb.)\n- Arabaya bağlı herhangi bir aksesuarda eksiklik olmaması\n- Arabanın noterde geri alınması için yasal bir engel olmaması (kredi, vergi vb.)\n- Arabanın satın alındığından itibaren en fazla 500 km kullanılmış olması\n\n\nletgo, garanti kapsamında herhangi bir zamanda değişiklik yapma hakkını saklı tutar. Değişim imkanı yalnızca yılda bir kez geçerlidir. Değişim hakkı yalnızca son tüketiciler için geçerli olup galeri ya da ticari alıcılar için geçerli değildir.",
                q: 'Değişim şartları nedir?',
                categoryId: 'buy'
            },
            {
                a: 'Misafirlerimizin ve çalışanlarımızın sağlığı bizim için çok önemli. Bu sebeple ekspertiz merkezimizde tüm Covid-19 önlemlerini alıyoruz. Ekspertiz merkezimiz düzenli aralıklarla dezenfekte ediliyor. Merkezimizdeki tüm otoplus profesyonellerimiz ekspertiz süresince eldiven ve maske kullanıyor; merkezimizdeki tüm çalışanlarımız tüm gün boyunca tüm gereken önemleri alıyorlar. Merkezimize maskesiz giriş yapılamayacağı için maskeyle gelmeni rica ediyoruz.',
                q: 'Koronavirüse karşı ne önlemler alınıyor?',
                categoryId: 'buy'
            }
        ]
    },
    olxpl: {
        title: 'Najczęstsze pytania',
        content: [
            {
                q: 'Czy kupujecie każdy samochód?',
                a: `Kupujemy tylko auta zarejestrowane w Polsce.

Nie kupujemy samochodów poważnie uszkodzonych, po ciężkich szkodach, bez prawa rejestracji, przeznaczonych na części, samochodów z kierownicą po prawej stronie tzw. „przekładek” oraz aut po nauce jazdy. W praktyce jednak jest to znikomy procent aut, które przyszło nam wyceniać.`
            },
            {
                q: 'Czy wycena jest darmowa?',
                a: 'Zarówno wycena online, która jest orientacyjną wyceną przed inspekcją, jak i wycena w punkcie inspekcji 3-2-1 SPRZEDANE! są darmowe – niezależnie od tego, czy zdecydujesz się na sprzedaż, czy nie.'
            },
            {
                q: 'Czy po inspekcji muszę sprzedać swój samochód 3-2-1 SPRZEDANE! ?',
                a: 'Nie. Po otrzymaniu wyceny sam decydujesz, czy sprzedasz nam swój samochód.'
            },
            {
                q: 'Czy wycena online i wycena po inspekcji mogą się różnić?',
                a: 'Bardzo się staramy, żeby wycena online była precyzyjna, ale dopiero podczas inspekcji jesteśmy w stanie ocenić dokładnie stan techniczny samochodu, zweryfikować dokumenty i historię serwisową. Podczas oględzin sprawdzamy prawie 200 kryteriów. Finalna propozycja może różnić się od tej zaproponowanej wstępnie online.'
            },
            {
                q: 'Co jest podstawą wyceny samochodu?',
                a: 'Przy wycenie samochodu bierzemy pod uwagę model, rocznik, wyposażenie, pochodzenie auta, przebieg, liczbę dotychczasowych właścicieli, historię serwisową pojazdu oraz wynik naszej inspekcji technicznej.'
            },
            {
                q: 'Jak długo będę czekać na płatność?',
                a: 'Zlecenie płatności jest wysłane tuż po podpisaniu umowy kupna-sprzedaży. Najczęściej pieniądze trafiają na konto sprzedającego w ciągu 48 godzin.'
            },
            {
                q: 'Jak wygląda wycena i inspekcja samochodu?',
                a: `Etapy wyceny w punkcie 3-2-1 SPRZEDANE:

**Weryfikacja dokumentacji samochodu.**

Sprawdzimy dokumenty potwierdzające pochodzenie auta oraz historię obsługi serwisowej (książka serwisowa i/lub faktury napraw i przeglądów).

**Oględziny samochodu.**

Ocenimy stan wewnętrzny i zewnętrzny Twojego auta pod kątem zużycia, uszkodzeń i jakości wykonania ewentualnych napraw blacharsko-lakierniczych. Dokonamy pomiaru grubości powłoki lakierniczej całego samochodu. Sprawdzimy stan i kompletność wyposażenia auta. Zweryfikujemy przebieg samochodu.

**Jazda testowa.**

Jazda próbna pozwoli nam ocenić stan techniczny samochodu. Zweryfikujemy pracę silnika, skrzyni biegów, zawieszenia i innych elementów wyposażenia auta.

**Oględziny mechaniczne samochodu.**

Sprawdzimy ewentualne problemy i usterki mechaniczne, które wyniknęły podczas jazdy testowej.`
            },
            {
                q: 'Co warto zabrać ze sobą na inspekcję?',
                a: `Należy zabrać ze sobą:

- dowód osobisty i drugi dokument ze zdjęciem (paszport, prawo jazdy),
- dowód rejestracyjny z ważnym badaniem technicznym,
- kartę pojazdu,
- dokument potwierdzający ubezpieczenie pojazdu,
- komplet kluczyków,
- kod do radia (jeżeli samochód posiada kodowane radio),
- piloty oraz kod do niefabrycznego alarmu (jeżeli taki masz zamontowany w samochodzie),
- adapter do nakrętek zabezpieczających koła (jeżeli koła Twojego samochodu mają takie zabezpieczenie);

Właściciele samochodów z instalacją LPG proszeni są o dostarczenie nam także:

- faktury na montaż instalacji, w której powinien być wpisany VIN samochodu oraz jego numer rejestracyjny (w przypadku jeżeli instalacja LPG została zamontowana po dokonaniu rejestracji pojazdu),
- atestu na butlę z gazem,
- wyciągu ze świadectwa homologacji sposobu montażu instalacji;

Jeśli nie jesteś właścicielem samochodu musisz posiadać pełnomocnictwo współwłaściciela poświadczone notarialnie.

Pamiętaj także, że jeżeli Twój samochód jest zarejestrowany na firmę to musisz wystawić fakturę VAT lub rachunek na podstawie którego dokonasz przeniesienia własności na 3-2-1 SPRZEDANE. Potrzeby będzie także wyciąg z KRS lub wpis do CEIDG (za zgodność z oryginałem). W przypadku samochodów wykupionych z leasingu potrzebny będzie także dowód ich wykupu.`
            },
            {
                q: 'Czy mogę sprzedać samochód zarejestrowany na firmę?',
                a: 'Jak najbardziej! Proces może być jednak trochę dłuższy, ponieważ weryfikacja sprzedającego w przypadku pojazdów zarejestrowanych na firmę wymaga większego zaangażowania.'
            },
            {
                q: 'Czy mogę dostać fakturę za sprzedane auto?',
                a: 'Tak, wystawiamy FV.'
            },
            {
                q: 'Co podnosi wartość mojego auta?',
                a: `Cenę sprzedaży samochodu podnoszą:

- książka serwisowa,
- dodatkowy komplet opon,
- faktury za serwis samochodu,
- dodatkowe wyposażenie np. bagażnik dachowy;`
            },
            {
                q: 'Czym zajmuje się 3-2-1 SPRZEDANE! ?',
                a: 'W 3-2-1 SPRZEDANE! sprzedasz samochód szybko, bezpiecznie i w przyjaznej atmosferze. Darmowa wycena, inspekcja i szybka sprzedaż.'
            },
            {
                q: 'Czy można u Was kupić samochód?',
                a: 'Polecamy skorzystanie z oferty [OTOMOTO KLIK](https://otomotoklik.pl/). Jest to nowoczesna platforma ze starannie sprawdzonymi, używanymi samochodami, które mają [Cyfrowy Paszport Pojazdu®](https://cyfrowypaszportpojazdu.pl/). Proces pozyskiwania i przygotowania aut do sprzedaży został opracowany na bazie doświadczeń największego portalu sprzedaży samochodów w Polsce. Dzięki temu klient ma pewność, że kupuje pewny i w pełni sprawdzony pojazd.'
            }
        ]
    },
    olxin: {
        title: 'Frequently Asked Questions',
        content: [
            {
                a: 'Book an appointment on the OLXmobbi website or call us on 9821110000. You only need to fill out details about your car like make, model, year and mileage and provide us your basic contact information.',
                q: 'How do I book an appointment with OLXmobbi?'
            },
            {
                a: 'Absolutely! OLXmobbi offers home inspection in most cities at your preferred location. You can book an appointment for home inspection through our website or by calling us on 9821110000.',
                q: 'Can OLXmobbi inspect my car at my home or office?'
            },
            {
                a: 'It takes about 45 mins for our Inspection Engineer to inspect the car.',
                q: 'How long will it take to inspect my car?'
            },
            {
                a: 'One of our trusted and trained ‘inspection engineers’ will inspect your car on more than 100 parameters, which also includes a short test drive and evaluate your car’s condition to get you the best offer.',
                q: 'How does the inspection take place?'
            },
            {
                a: 'Yes, you can easily reschedule the appointment by calling us on 9821110000.',
                q: 'Can I reschedule my appointment?'
            },
            {
                a: 'OLXmobbi does not buy commercial vehicles as of now.',
                q: 'Can I sell my commercial car with olx autos?'
            },
            {
                a: 'The exact value of the car can only be assessed after an inspection by OLX Auto’s professional car inspectors. Factors such as the condition of the car, usage of the car and market intelligence incorporating current demand and supply situations play an important role in the generation of the best.',
                q: 'How is the value of my car calculated?'
            },
            {
                a: 'Once our inspection engineer evaluates your car, we’ll contact you with a fair value we would expect on the market. With your confirmation, we will proceed to find you the best offer.',
                q: 'How much time will I have to wait to get an offer for my car?'
            },
            {
                a: 'Please carry all documents of the car, like the original registration book, warranty book, insurance and service history documents. In case of duplicate papers or digital copies, inform us in advance.',
                q: 'What paperwork do I need to sell my car with olx autos?'
            },
            {
                a: 'We, at OLXmobbi, are totally transparent when it comes to our valuations and there are no hidden fees. We offer a free valuation service and free vehicle collection.',
                q: 'Are there any hidden fees?'
            },
            {
                a: 'You are not obligated to sell if you don’t find an offer to suit you.',
                q: 'What if I did not like the price offered by you?'
            },
            {
                a: 'The registered owner of the car must be present for the payment. However, we understand this might not be possible sometimes. In that case, you may provide a signed authorization from the owner to complete the transaction on their behalf.',
                q: 'What else do I have to take care of?'
            },
            {
                a: 'There may be a difference in the amount you agree upon and the amount you receive while selling the car. This can be only because we withhold certain amounts for a few cases. This holdback amount is refunded once the conditions are fulfilled. Holdback cases: Holdbacks for party presence requirements (only for some RTOs) Bank NOC in case of hypothecation removal on the car (only for cars where there is a loan) Holdbacks if hypothecation removal is required Holdback for the service history of the car\n',
                q: 'Will I get the exact amount that is being offered to me?'
            },
            {
                a: 'OLXmobbi strongly recommends taking all your belongings before handover. FastTag is also labeled as the personal belonging of the customer hence we recommend to deactivate the same if you are selling your car to OLXmobbi. Please note that OLXmobbi is not responsible for the reimbursement of any deductions from the FastTag wallet after the handover of the vehicle.',
                q: 'Do I need to remove my fasttag while handing over the vehicle?'
            },
            {
                a: 'RC Transfer is initiated only after the car is sold to the end buyer by the Channel Partners which normally takes 180 days. Once the end buyer is finalized and the documentation is approved, we normally take 30-60 days to complete the RC Transfer process.',
                q: 'When can I get my rc transfer completed?'
            },
            {
                a: 'OLXmobbi supports you until the entire process of transfer is completed. Every customer who sells their car to OLXmobbi is covered by the Vehicle-Owner Protection Policy of OLX. Which gives a coverage of upto 15 lakhs and protects you from:\n\n1.  Traffic Challans on the Vehicle.\n2.  Court of Tribunal proceedings for the vehicle\n3.  Accidents by the vehicle\n4.  Police, Court and Legal notices received for the vehicle\n5.  Any misuse of the vehicle.',
                q: 'What happens if there is any mishap in these 180 days with my car until you find an end buyer?'
            },
            {
                a: 'Our team will keep you updated with the status of your transfer however, you can also check the status of your RC transfer directly from [https://parivahan.gov.in/parivahan/](https://parivahan.gov.in/parivahan/)\n\\*\\*Delhi NCR customers, kindly check after 360 days.',
                q: 'Where can I check my RC transfer status?'
            },
            {
                a: 'OLXmobbi supports you until the entire process of repayment of Challans. Every customer who sells their car to OLXmobbi is covered by the Vehicle-Owner Protection Policy of OLX. Which protects you from:\n\n1.  Traffic Challans on the Vehicle.\n2.  Court of Tribunal proceedings for the vehicle\n3.  Accidents by the vehicle\n4.  Police, Court and Legal notices received for the vehicle\n5.  Any misuse of the vehicle.',
                q: 'I have got a challan/court notice for my pending challan on my car despite selling my car to olx autos. What should I do now?'
            }
        ],
        notFoundTitle: 'No results found.',
        notFoundSubtitle: 'We could not find any results for "searchValue". Please try a different search or try rephrasing your question.',
        showTabs: false,
        goBackBtnTitle: 'BACK'
    }
};

export const listingFaqKeys = {
    USED_CAR: 'usedCar',
    USED_CAR_IN_CITY: 'usedCarInCity',
    USED_CAR_IN_BRAND: 'usedCarInBrand',
    USED_CAR_IN_CITY_AND_BRAND: 'usedCarInCityAndBrand'
};

export const listingFaqs = {
    [listingFaqKeys.USED_CAR]: {
        title: 'FAQS',
        content: [
            {
                q: 'Why buy a second hand car?',
                a: 'There are several reasons why buying a second-hand car might be a good idea in India. Firstly, used cars are generally less expensive than brand new cars, which means you can save a lot of money. Secondly, used cars do not depreciate as quickly as new cars, so you may not lose as much money if you sell it later.'
            },
            {
                q: 'What is the starting price of a used car?',
                a: 'The starting price of a used car can vary greatly depending on a number of factors, such as the make and model of the car, its age, and its condition. In India, you can find used cars for as little as INR 50,000 or less, but there are also many used cars that are more expensive.'
            },
            {
                q: 'What are the popular second hand car models?',
                a: 'Some of the most popular second-hand car models in India include the Maruti Suzuki Swift, Hyundai i10, Honda City, Toyota Innova, and Mahindra Scorpio. These models are known for their reliability, fuel efficiency, and affordable maintenance costs.'
            },
            {
                q: 'Which car brands have the most resale value?',
                a: 'In India, some of the car brands with the highest resale value include Maruti Suzuki, Hyundai, Honda, Toyota, and Mahindra. These brands are known for their durability, low maintenance costs, and wide-spread service network.'
            },
            {
                q: 'What are the most reliable second hand cars under 5 lakh?',
                a: 'Some of the most reliable second-hand cars under 5 lakh include Maruti Suzuki Wagon R, Hyundai Santro, Tata Indica, and Maruti Suzuki Alto. These cars are known for their fuel efficiency, low maintenance costs, and affordable spare parts availability.'
            },
            {
                q: 'What are the best SUV second hand cars?',
                a: 'Best second-hand SUV models include the Mahindra Scorpio, Tata Safari, Ford Endeavour, Toyota Fortuner, and Hyundai Creta. These models are known for their ruggedness, reliability, and comfortable driving experience.'
            },
            {
                q: 'How can I know if the price of the second hand car is appropriate?',
                a: 'To know if the price of a second-hand car is appropriate, you can check the market price of the same model and make on online portals or car valuation websites. You can also consult with a mechanic or a trusted car dealer to get an idea of the current market price of the car.'
            },
            {
                q: 'Can I negotiate the price on an old car?',
                a: 'Yes, in India, it is common to negotiate the price of a used car. However, the extent to which the price can be negotiated depends on various factors such as the condition of the car, the demand in the market, and the urgency of the seller to sell the car.'
            }
        ],
        notFoundTitle: 'No results found.',
        notFoundSubtitle: 'We could not find any results for "searchValue". Please try a different search or try rephrasing your question.',
        showTabs: false
    },
    [listingFaqKeys.USED_CAR_IN_CITY]: {
        title: 'FAQS',
        content: [
            {
                q: 'What will be the starting price of a used car in {{City}}?',
                a: 'The starting price of a used car in {{City}} can vary greatly depending on the make and model of the car, its age, and its condition. However, in general, you can find used cars for as little as INR 50,000 or less, while some premium models may cost several lakhs of rupees.'
            },
            {
                q: 'How many used cars are available in {{City}}?',
                a: 'The number of used cars available in {{City}} will depend on various factors such as the demand for used cars, the population of the city, and the number of car dealerships operating in the city. However, you can find a wide range of used cars in {{City}} from various brands and models.'
            },
            {
                q: 'Where can I buy used cars in {{City}}?',
                a: 'You can buy used cars in {{City}} from a number of sources, including online marketplaces such as CarDekho, CarWale, and Olx, as well as from local car dealerships and individual sellers. It is always advisable to do some research and choose a reliable seller who has a good reputation in the market.'
            },
            {
                q: 'What are the most popular brands available for used cars in {{City}}?',
                a: 'Some of the most popular brands available for used cars in {{City}} include Maruti Suzuki, Hyundai, Honda, Toyota, and Mahindra. These brands are known for their reliability, low maintenance costs, and high resale value.'
            },
            {
                q: 'How many used diesel cars are available in {{City}}?',
                a: 'The number of used diesel cars available in {{City}} will depend on the demand for diesel cars in the city, as well as the number of diesel car owners who are looking to sell their cars. However, diesel cars are generally more popular in India due to their higher fuel efficiency, and you can find a significant number of used diesel cars in {{City}}.'
            },
            {
                q: 'What body types are available for used cars in {{City}}?',
                a: 'You can find a wide range of body types for used cars in {{City}}, including hatchbacks, sedans, SUVs, and MPVs. The availability of each body type will depend on the market demand and the specific preferences of the buyers in {{City}}.'
            },
            {
                q: 'What to check before buying a second hand car in {{City}}?',
                a: "Before buying a second-hand car in {{City}}, it is important to check the car's overall condition, including its engine, transmission, brakes, suspension, and electrical systems. It is also advisable to take the car for a test drive and to check its service history and documentation, including the RC book and insurance papers."
            },
            {
                q: 'What are the most reliable second hand cars under 5 lakh to own in {{City}}?',
                a: 'In {{City}}, some of the most reliable second-hand cars under 5 lakh include the Maruti Suzuki Wagon R, Hyundai Santro, Tata Indica, and Maruti Suzuki Alto. These cars are known for their fuel efficiency, low maintenance costs, and availability of spare parts. However, it is always advisable to get a professional mechanic to inspect the car thoroughly before making a purchase.'
            }
        ],
        notFoundTitle: 'No results found.',
        notFoundSubtitle: 'We could not find any results for "searchValue". Please try a different search or try rephrasing your question.',
        showTabs: false
    },
    [listingFaqKeys.USED_CAR_IN_BRAND]: {
        title: 'FAQS',
        content: [
            {
                q: 'How many used {{Brand}} cars are available?',
                a: 'There are tonnes of {{Brand}} cars available in the used market. From small and compact cars available at affordable rates to larger cars with better features, comfort and performance, there are all types of {{Brand}} cars available in the used market.\n \nWhen it comes to choosing your final car, always factor in your specific need and requirements to get the most suitable car for yourself. For instance, if your travel is mostly limited to city commutes, getting a car with good gas mileage and city-friendly dimensions would make a lot of sense.'
            },
            {
                q: 'Which is the inexpensive model of used {{Brand}} car?',
                a: '{{Brand}} is a household name in the Indian automotive industry and offers a wide range of cars. Therefore, you can easily spot examples of {{Brand}} in the used market. When it comes to the inexpensive models of used {{Brand}} cars, naturally, the models with a lower starting price (new price) can be found at inexpensive rates in the second-hand market.\n \nMoreover, other factors, such as resale value and age of the car, also decide its final price in the second-hand market. Therefore, when looking for inexpensive used models of {{Brand}}, you can slightly go towards older cars to find a better bargain. However, before this bargain allures you, do remember the fair share of challenges of owning such old cars.'
            },
            {
                q: 'What are the most popular 2nd hand {{Brand}} cars available?',
                a: "India has over a dozen car brands, but the masses love just a few. {{Brand}} is one such brand, which is quite popular in the Indian automotive industry. This Indian car brand has been offering quite capable products that can give tough competition to the whole segment.\n \n{{Brand}} cars are an excellent blend of performance with features and luxury. If you are thinking about the most popular 2nd hand {{Brand}} cars available, it's natural that the best-selling new {{Brand}} models will also be quite popular in the used market. However, do remember that they will also command a slightly higher price tag in the used market because of their credibility and popularity, which eventually enhances their resale value."
            },
            {
                q: 'What are the popular used {{Brand}} cars under 2 lakhs available?',
                a: "If you're a new driver who is still learning the basics of driving, a used automobile is always a better alternative. When talking about the popular used {{Brand}} cars under 2 lakhs, it is the older models that can be found at such inexpensive rates in the second-hand market.\n \nTherefore, will have to go slightly towards older cars to find a bargain under 2 lakhs. However, before you settle for this deal, consider the drawbacks of having such old vehicles.\n \nRegistration timelines, dated technology, raised maintenance, and availability of parts, are only a few issues to name. Therefore, go for these cars only if you are on a very strict budget."
            },
            {
                q: 'What is the starting price of a used {{Brand}} car?',
                a: 'The starting price of a used car can go as little as under Rs 1 lakh. However, that also means the vehicle is going to be a very old example. Furthermore, do not forget the challenges of owning such old cars. Therefore, we recommend always looking for cars that are in good condition.'
            },
            {
                q: 'Why buy a {{Brand}} second-hand car?',
                a: 'After all, buying a second-hand {{Brand}} car makes more sense than buying a new one, especially in the current market scenario. There are multiple benefits of buying a used car such as better value for money and less waiting time. Moreover, used cars depreciate lesser. They also help you save on road taxes and insurance.'
            }
        ],
        notFoundTitle: 'No results found.',
        notFoundSubtitle: 'We could not find any results for "searchValue". Please try a different search or try rephrasing your question.',
        showTabs: false
    },
    [listingFaqKeys.USED_CAR_IN_CITY_AND_BRAND]: {
        title: 'FAQS',
        content: [
            {
                q: 'What will be the starting price of a used {{Brand}} car in {{City}}?',
                a: 'Finding a used car in {{City}} has become fairly easy with technological advancement. You can sit in the comfort of your home or office and look for sellers of used cars. The starting price of a used car in {{City}} can vary greatly depending on the make and model of the {{Brand}} car, its age, and its condition. However, in general, you can find used cars for as little as INR 50,000 or less, while some premium models may cost several lakhs of rupees.'
            },
            {
                q: 'How many used {{Brand}} cars are available in {{City}}?',
                a: 'The internet has made it easy to account for the number of cars available in {{City}} or any other city. The number of used cars available in {{City}} will depend on various factors such as the demand for used {{Brand}} cars, the population of the city, and the number of car dealerships operating in the city. However, you can find a wide range of used {{Brand}} cars in {{City}}.'
            },
            {
                q: 'Where can I buy used {{Brand}} cars in {{City}}?',
                a: 'You can easily buy used {{Brand}} cars in {{City}} by hopping on to the internet and filling in the details of the car that you would like to buy. You can fill in the model, year, miles driven, condition, etc., and find a used car to buy in {{City}}. You can also talk to the people you know and ask whether a used car is available in your city.'
            },
            {
                q: 'What are the most popular {{Brand}} used cars in {{City}}?',
                a: 'Some of the most popular used car brands in {{City}} could be Maruti Suzuki, Hyundai, Honda, Toyota, and Mahindra. However, the popularity of these brands may vary based on the specific preferences and requirements of the buyers in the city.'
            },
            {
                q: 'How many used {{Brand}} diesel cars are available in {{City}}?',
                a: 'The number of used {{Brand}} diesel cars available in {{City}} will depend on the demand for diesel cars in the city, as well as the number of diesel car owners who are looking to sell their cars. However, diesel cars are generally more popular in India due to their higher fuel efficiency, and you can find a significant number of used {{Brand}} diesel cars in {{City}}.'
            },
            {
                q: 'What to check before buying a {{Brand}} second-hand car in {{City}}?',
                a: "Before buying a second-hand {{Brand}} car in {{City}}, you must check various things. You must always do your due diligence and must obtain all information on the used car. You must check the car's condition, price, the number of miles driven, fuel type, loan (if any), and legal obligations or charges (if any). You must always ensure there is no obligation on the car as it can be transferred to you if you don't clear it before purchasing it."
            },
            {
                q: 'What is the most reliable {{Brand}} second-hand cars under Rs 5 lakh to own in {{City}}?',
                a: 'It is difficult to say which car you will get for less than Rs. 5 lacs as various factors determine the price of a used car. You can get a car of higher value for a lower price if it has been driven more than a car with a lower price but fewer miles.'
            }
        ],
        notFoundTitle: 'No results found.',
        notFoundSubtitle: 'We could not find any results for "searchValue". Please try a different search or try rephrasing your question.',
        showTabs: false
    }
};
