import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './FaqContent.APP_TARGET.scss';
import Collapsible from 'Components/Collapsible/Collapsible';
import CollapsibleV2 from 'Components/CollapsibleV2/CollapsibleV2';

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { QUESTION_SEO } from 'Constants/faq';

const markdownPlugins = [gfm];

export const FaqContent = ({ faqs = [], track, isClassified }) => {
    const [collapsed, setCollapsed] = useState(new Array(faqs.length).fill(true));

    useEffect(() => {
        setCollapsed(new Array(faqs.length).fill(true));
    }, [faqs]);

    const handleCollapse = (toggleCollapsed, index) => {
        const faq = faqs[index];

        track('faq_tap', {
            chosen_option: faq.q,
            ...faq.categoryId ? { select_from: faq.categoryId } : {}
        });
        setCollapsed([...collapsed.slice(0, index), toggleCollapsed, ...collapsed.slice(index + 1)]);
    };

    const CollapsibleComponent = isClassified ? CollapsibleV2 : Collapsible;

    return (<div className={ css.contentWrapper }>
        {faqs.map(({ q, a }, index) => {
            function onCollapsed(toggleCollapsed) {
                handleCollapse(toggleCollapsed, index);
            }

            return (
                <div itemScope itemProp={ QUESTION_SEO.ITEM_PROP } itemType={ QUESTION_SEO.ITEM_TYPE } key={ `faq_${index}` }>
                    <CollapsibleComponent
                        title={ q }
                        collapsed={ collapsed[index] }
                        onCollapsed={ onCollapsed }
                        key={ `faq_${index}` }
                        data-aut-id={ `faq_q${index}` }
                        titleClassName={ css.title }
                        className={ css.collapsible }
                        isClassified={ isClassified }
                    >
                        <div className={ css.content }>
                            <ReactMarkdown remarkPlugins={ markdownPlugins }>{ a }</ReactMarkdown>
                        </div>
                    </CollapsibleComponent>
                </div>
            );
        })}
    </div>);
};

FaqContent.propTypes = {
    faqs: PropTypes.array,
    track: PropTypes.func.isRequired,
    isClassified: PropTypes.bool
};

FaqContent.defaultProps = {
    isClassified: false
};

export default FaqContent;
