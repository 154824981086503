import { useMemo } from 'react';
import { clone } from 'Helpers/objects';
import { MAKE_KEY } from 'Constants/faq';
import { capitalize } from 'Helpers/strings';
import { listingFaqKeys } from '../../olxautos/constants/faq';

export const getFaqType = (geoID, filter) => {
    let isMakeFilter = false;
    const filterArray = filter?.split('_');

    if (filterArray && filterArray?.length > 0 && filterArray[0] === MAKE_KEY) {
        isMakeFilter = true;
    }

    if (geoID && !isMakeFilter) {
        return listingFaqKeys.USED_CAR_IN_CITY;
    }
    else if (!geoID && isMakeFilter) {
        return listingFaqKeys.USED_CAR_IN_BRAND;
    }
    else if (geoID && isMakeFilter) {
        return listingFaqKeys.USED_CAR_IN_CITY_AND_BRAND;
    }
    return listingFaqKeys.USED_CAR;
};

export const getTranspolatedFaqs = (faqs = [], faqType = '', params = {}, filter = '') => {
    let { geoSlug = '' } = params;

    geoSlug = capitalize(geoSlug);
    let modelName = '';
    const filterArray = filter?.split('_');

    if (filterArray && filterArray?.length > 0 && filterArray[0] === MAKE_KEY) {
        modelName = filterArray?.[2].replace('-', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }

    switch (faqType) {
        case listingFaqKeys.USED_CAR_IN_CITY:
            return faqs.map(elem => {
                const q = elem.q.replaceAll('{{City}}', geoSlug);
                const a = elem.a.replaceAll('{{City}}', geoSlug);

                elem.q = q;
                elem.a = a;
                return elem;
            });
        case listingFaqKeys.USED_CAR_IN_BRAND:
            return faqs.map(elem => {
                const q = elem.q.replaceAll('{{Brand}}', modelName);
                const a = elem.a.replaceAll('{{Brand}}', modelName);

                elem.q = q;
                elem.a = a;
                return elem;
            });
        case listingFaqKeys.USED_CAR_IN_CITY_AND_BRAND:
            return faqs.map(elem => {
                const q = elem.q.replaceAll('{{City}}', geoSlug);
                const a = elem.a.replaceAll('{{City}}', geoSlug);

                const q1 = q.replaceAll('{{Brand}}', modelName);
                const a1 = a.replaceAll('{{Brand}}', modelName);

                elem.q = q1;
                elem.a = a1;
                return elem;
            });
        default:
            return faqs;
    }
};

export const useFAQContent = ({ location, params, config, faqs, isListingFaq }) => {
    const { type = '' } = location.query;
    const siteCode = config.get('siteCode');

    const { geoID = '' } = params;
    const { query: { filter = '' } = {}} = location;
    const faqType = useMemo(() => getFaqType(geoID, filter), [geoID, filter]);

    const data = isListingFaq ? clone(faqs[faqType]) : faqs[siteCode];
    const { title, content: sectionContent = [], page = {}, tabs = null, notFoundTitle, notFoundSubtitle } = data || {};
    const showTabs = tabs !== null;
    const content = type && page[type] && !showTabs ? page[type] : sectionContent;

    return {
        content,
        showTabs,
        title,
        tabs,
        faqType,
        filter,
        notFoundTitle,
        notFoundSubtitle
    };
};
