import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './FAQ.APP_TARGET.scss';
import { Tabs } from 'panamera-react-ui';
import TextInputField from 'Components/TextInputField/TextInputField';
import FaqContent from './FaqContent/FaqContent';
import Fuse from 'fuse.js';
import { DATA_AUT_IDS } from 'Constants/constants';
import { getTranspolatedFaqs } from 'Hooks/useFAQContent/useFAQContent';
import { withSchema } from 'HOCs/withSchema/withSchema';

const { TabsContainer, TabContent, TabTitle } = Tabs;

export const getFilteredFaqsForSearchQuery = (faqs, searchQuery) => {
    if (searchQuery === '') {
        return faqs;
    }
    // standard way of transforming letters with accents to letters withouth them.
    // source: https://stackoverflow.com/a/37511463
    const removeAccentsFromText = text => text.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    const searchOptions = {
        keys: [
            { name: 'q', getFn: ({ q }) => removeAccentsFromText(q), weight: 0.7 },
            { name: 'a', getFn: ({ a }) => removeAccentsFromText(a), weight: 0.3 }
        ],
        ignoreLocation: true,
        // this allows small typos when searching
        threshold: 0.15,
        useExtendedSearch: true
    };

    const fuse = new Fuse(faqs, searchOptions);

    return fuse.search(removeAccentsFromText(searchQuery)).map(({ item }) => item);
};

export const getFilteredFaqsForTab = (faqs, categoryId) => {
    return faqs.filter(faq => faq.categoryId === categoryId);
};

export const FAQ = ({
    content,
    showTabs,
    title,
    tabs,
    faqType,
    filter,
    notFoundTitle,
    notFoundSubtitle,
    shouldShowHeader,
    placeholder,
    isClassified,
    isListingFaq,
    track,
    params
}) => {
    const [input, setInput] = useState('');
    const [filteredFaqs, setFilteredFaqs] = useState(content);
    const [selectedTabIdx, setSelectedTabIdx] = useState(0);

    let showNotFoundMessage = input && !!(filteredFaqs && filteredFaqs.length === 0);

    function renderErrorMessage() {
        return (
            <div className={ css.errorMessageContainer }>
                <div className={ css.errorMessageTitle }>{notFoundTitle}</div>
                <div className={ css.errorMessageSubTitle }>{notFoundSubtitle?.replace('searchValue', input)}</div>
            </div>
        );
    }

    function getTabs() {
        const tabsToDisplay = [];

        tabs.forEach(({ categoryId, title }, idx) => {
            tabsToDisplay.push(
                <TabTitle
                    key={ `tabtitle${categoryId}` }
                    listItemClassName={ css.tab }
                    selectedClass={ css.selected }
                    className={ css.noOutline }
                    id={ idx }
                >
                    {title}
                </TabTitle>
            );

            const tabContent = getFilteredFaqsForTab(filteredFaqs, categoryId);

            if (idx === selectedTabIdx) {
                showNotFoundMessage = input && tabContent.length === 0;
            }

            tabsToDisplay.push(
                <TabContent
                    key={ `tabcontent${categoryId}` }
                >
                    {renderContent(tabContent)}
                </TabContent>
            );
        });

        return tabsToDisplay;
    }

    function renderTabsData() {
        return (
            <TabsContainer
                listClassName={ css.tabContainer }
                onClick={ toggleSelectedTab }
            >
                {getTabs()}
            </TabsContainer>
        );
    }

    function toggleSelectedTab(tabIdx) {
        setSelectedTabIdx(tabIdx);
    }

    function renderContent(faqs, params) {
        if (isListingFaq) {
            const transpolatedFaqs = getTranspolatedFaqs(content, faqType, params, filter);

            faqs = transpolatedFaqs;
        }
        return (<FaqContent faqs={ faqs } track={ track } isClassified={ isClassified } />);
    }

    function handleSearch(value) {
        const searchedItems = getFilteredFaqsForSearchQuery(content, value);

        setFilteredFaqs(searchedItems);
        setInput(value);
    }

    return (
        <div className={ css.contentContainer } data-aut-id="faq-container">
            { shouldShowHeader && <div className={ css.headingWrapper } data-aut-id={ DATA_AUT_IDS.FAQ_HEADING_WRAPPER }>
                <div className={ css.heading }>
                    <div className={ css.title }>{title}</div>
                    <div className={ css.search }>
                        <TextInputField
                            id="search"
                            name="search"
                            value={ input }
                            maxLength={ 20 }
                            onChange={ handleSearch }
                            icon="search"
                            placeholder={ placeholder }
                            saveSpaceForIcons
                            type="text"
                        />
                    </div>
                </div>
            </div> }
            { isListingFaq && <div className={ css.headerTitle } data-aut-id={ DATA_AUT_IDS.FAQ_HEADER_TITLE }>{title}</div> }
            {showTabs ? renderTabsData() : renderContent(filteredFaqs, params)}

            {showNotFoundMessage && renderErrorMessage()}
        </div>
    );
};

FAQ.propTypes = {
    content: PropTypes.array,
    showTabs: PropTypes.bool,
    title: PropTypes.string,
    tabs: PropTypes.array,
    faqType: PropTypes.string,
    filter: PropTypes.string,
    notFoundTitle: PropTypes.string,
    notFoundSubtitle: PropTypes.string,
    shouldShowHeader: PropTypes.bool,
    placeholder: PropTypes.string,
    isClassified: PropTypes.bool,
    isListingFaq: PropTypes.bool,
    track: PropTypes.func,
    params: PropTypes.shape({ geoSlug: PropTypes.string })
};

export default React.memo(withSchema('FAQPage')(FAQ));
