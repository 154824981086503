export const QUESTION_SEO = {
    MAIN_ENTITY: 'mainEntity',
    ITEM_TYPE: 'https://schema.org/Question',
    ITEM_PROP: 'name'
};

export const ANSWER_SEO = {
    ITEM_TYPE: 'https://schema.org/Answer',
    ITEM_PROP: 'acceptedAnswer'
};

export const MAKE_KEY = 'make';

