import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import withConfig from 'HOCs/withConfig/withConfig';
import withRouter from 'HOCs/withRouter';
import { setBrowsingMode } from 'Actions/track';
import withTrack from 'HOCs/withTrack/withTrack';
import { checkIfMxCL } from 'Helpers/isMxcl';
import { useFAQContent } from 'Hooks/useFAQContent/useFAQContent';

import FAQ from './FAQ';

export const FAQWrapper = ({ intl, config, location, setBrowsingMode, track, faqs, shouldShowHeader, isListingFaq, params }) => {
    const {
        content,
        showTabs,
        title,
        tabs,
        faqType,
        filter,
        notFoundTitle,
        notFoundSubtitle
    } = useFAQContent({ location, params, config, faqs, isListingFaq });

    useEffect(() => {
        setBrowsingMode('FAQ Page');
        track('page_open', { select_from: 'faq' });
    }, [setBrowsingMode, track]);

    const isClassified = !checkIfMxCL(config);

    return (
        <FAQ
            content={ content }
            showTabs={ showTabs }
            title={ title }
            tabs={ tabs }
            faqType={ faqType }
            filter={ filter }
            notFoundTitle={ notFoundTitle }
            notFoundSubtitle={ notFoundSubtitle }
            shouldShowHeader={ shouldShowHeader }
            placeholder={ intl.formatMessage({ id: 'faq_placeholder' }) }
            isClassified={ isClassified }
            track={ track }
            params={ params }
            isListingFaq={ isListingFaq }
        />
    );
};

FAQWrapper.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
        query: PropTypes.object,
        pathname: PropTypes.string
    }),
    setBrowsingMode: PropTypes.func,
    track: PropTypes.func,
    faqs: PropTypes.object,
    shouldShowHeader: PropTypes.bool,
    isListingFaq: PropTypes.bool,
    params: PropTypes.object
};

FAQWrapper.defaultProps = {
    shouldShowHeader: true,
    isListingFaq: false,
    params: {}
};

const mapDispatchToProps = dispatch => {
    return {
        setBrowsingMode: mode => dispatch(setBrowsingMode(mode))
    };
};

export default React.memo(compose(
    withRouter,
    withConfig,
    injectIntl,
    withTrack,
    connect(null, mapDispatchToProps)
)(FAQWrapper));
